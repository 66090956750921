import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Select } from '../../atoms'

const ProcessingStateSelect = ({ id, disabled, hidden }) => {
  const { t } = useTranslation('caseProperty')

  const items = [
    {
      value: 'open',
      label: t('open')
    },
    {
      value: 'assigned',
      label: t('assigned')
    },
    {
      value: 'accepted',
      label: t('accepted')
    },
    {
      value: 'blocked',
      label: t('blocked')
    },
    {
      value: 'first_contacted',
      label: t('firstContacted')
    },
    {
      value: 'coverage_check',
      label: t('coverageCheck')
    },
    {
      value: 'in_progress',
      label: t('inProgress')
    },
    {
      value: 'completed',
      label: t('completed')
    },
    {
      value: 'checked',
      label: t('checked')
    },
    {
      value: 'settled',
      label: t('settled')
    },
    {
      value: 'transfer',
      label: t('transfer')
    },
    {
      value: 'jump_off',
      label: t('jumpOff')
    }
  ]
  const name = `processingsAttributes.${id}.processingState`
  const { control } = useFormContext()
  if (hidden) return null
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          {...field}
          variant="standard"
          maxWidth="unset"
          minWidth={160}
          items={items}
          label={t('processingState')}
          disabled={disabled}
        />
      )}
    />
  )
}
ProcessingStateSelect.propTypes = {
  id: PropTypes.number,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool
}
ProcessingStateSelect.defaultProps = {
  id: 0,
  hidden: false,
  disabled: false
}

export default ProcessingStateSelect
