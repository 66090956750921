import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import consumer from '../cable'
import { getUser, setUser as setStorageUser, removeUser as removeStorageUser } from './helper'

type ContextType = {
  user: any
  setUser: (userData: any) => void
  isEditor: boolean
  isAdmin: boolean
  isCreator: boolean
  isExternal: boolean
  isLegalAdvisor: boolean
  isEmployeeHohner: boolean
  removeUser: () => void
}

interface UserContextProviderProps {
  children: React.ReactNode
}

const initialValues = {
  name: 'Kai Mertens',
  firstname: 'Kai',
  lastname: 'Mertens',
  role: ''
}

const getStoreUser = () => {
  const user = { ...JSON?.parse(getUser() || '{}') }
  user.name = [user.firstname, user.lastname].join(' ')
  return user
}

const context = {
  user: { ...initialValues, ...getStoreUser() },
  setUser: () => {},
  isEditor: false,
  isAdmin: false,
  isCreator: false,
  isExternal: false,
  isLegalAdvisor: false,
  isEmployeeHohner: false,
  removeUser: () => {}
}

const UserContext = React.createContext<ContextType>(context)

export const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [user, setLocalUser] = useState(context.user)

  const isLegalAdvisor = useMemo(() => ['legal_advisor'].includes(user?.role), [user?.role])
  const isEditor = useMemo(() => ['user', 'legal_advisor'].includes(user?.role), [user?.role])
  const isAdmin = useMemo(() => ['admin'].includes(user?.role), [user?.role])
  const isCreator = useMemo(() => ['creator'].includes(user?.role), [user?.role])
  const isEmployeeHohner = user?.employee_hohner === true
  const isExternal = useMemo(
    () => ![...new Set([isAdmin, isEditor, isCreator, isLegalAdvisor])].includes(true),
    [isAdmin, isEditor, isCreator, isLegalAdvisor]
  )

  const setUser = (userData: any) => {
    if (userData) {
      Sentry.setUser({ email: userData?.email })
      setStorageUser(userData)
      setLocalUser((usr: any) => ({
        ...usr,
        ...userData,
        role: userData?.role,
        name: [userData.firstname, userData.lastname].join(' ')
      }))
    }
  }

  const removeUser = () => {
    Sentry.setUser(null)
    removeStorageUser()
    setLocalUser(initialValues)
  }

  useEffect(() => {
    console.debug('[*] creating user subscription')
    consumer.subscriptions.create(
      {
        channel: 'UserChannel',
        user_id: `${user?.id}`
      },
      {
        connected: () => {
          console.debug('[+] ActionCable - CONNECTED')
        },
        disconnected: () => {
          console.debug('[-] ActionCable - DISCONNECTED')
        },
        received: (data: any) => {
          console.debug('[+] ActionCable - received data: ', data)
          switch (data.action) {
            case 'call-accepted':
              console.debug('user', user)
              console.debug('data', data)
              console.debug('data.m2_case_id', data.m2_case_id)
              console.debug('data.processing_id', data.processing_id)
              if (isEditor) {
                window.location =
                  `cases/${data.m2_case_id}/processing/${data.processing_id}/?tab=processing&action=${data.action_id}` as any
              } else {
                window.location =
                  `/cases/${data.m2_case_id}/processing/${data.processing_id}/?subTab=processing&tab=processing-${data.processing_id}&action=${data.action_id}` as any
              }
              break
            default:
              break
          }
        }
      }
    )
    return () => {
      console.debug('[-] Unmounting UserContext')
      console.debug('consumer', consumer)
      consumer.disconnect()
    }
  }, [user, user?.id, isEditor])

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        removeUser,
        isEditor,
        isAdmin,
        isCreator,
        isExternal,
        isLegalAdvisor,
        isEmployeeHohner
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default UserContext
