import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { SEND_INVOICE_MUTATION } from '../../../graphql/mutations'
import { useCase } from '../../../utils/hooks'
import { Button, Input } from '../../atoms'
import MessageBox from '../MessageBox'
import DownloadButton from './DownloadButton'
import { IProps, INotification } from './interfaces'

const BillingButton: React.FunctionComponent<IProps> = ({
  label,
  disabled,
  mutation,
  helperText,
  value,
  options,
  callback
}) => {
  const { t } = useTranslation(['common', 'billing'])
  const [notificationVisible, setNotificationVisible] = useState<INotification | undefined>(
    undefined
  )

  const { processing } = useCase()
  const [load, setLoad] = useState(false)

  const [invoiceMutation] = useMutation(mutation || SEND_INVOICE_MUTATION, {
    ...options,
    variables: { ...(options?.variables || {}) },
    onCompleted: () => {
      setNotificationVisible({
        variant: 'success',
        title: t('success'),
        message: t('billing:success')
      })
      if (callback) callback()
    },
    onError: error => {
      setNotificationVisible({ variant: 'error', title: t('error'), message: error?.message })
    }
  })

  const renderHelperText = () => {
    const messages = []
    if (helperText) messages.push(helperText)
    return messages.length ? (
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          paddingLeft: '16px',
          paddingTop: '5px',
          whiteSpace: 'pre-wrap'
        }}
      >
        {messages.join(' \n')}
      </Typography>
    ) : null
  }

  const intern: boolean | undefined = processing?.assignedProcessingType?.intern

  return (
    <Grid container spacing={2}>
      {value?.invoiceNo && (
        <Grid item xs={12}>
          <Input
            label="Rechnungsnummer"
            onChange={() => {}}
            defaultValue={value.invoiceNo}
            disabled
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {value?.documentUrl ? (
          <DownloadButton options={{ url: value.documentUrl }} label={label} />
        ) : (
          <>
            {intern ? (
              <Typography
                variant="caption"
                sx={{ display: 'flex', width: '100%', paddingTop: '5px' }}
              >
                {t('billing:onlyInternAssignedProcessingType')}
              </Typography>
            ) : (
              <Button
                loading={load}
                loadingPosition="start"
                fullWidth
                onClick={async () => {
                  setLoad(true)
                  await invoiceMutation()
                  setLoad(false)
                }}
                disabled={disabled || load}
                // disabled={disabled || load || !processingId}
              >
                <>{label}</>
              </Button>
            )}
          </>
        )}
      </Grid>

      {renderHelperText()}

      <Typography
        variant="caption"
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', paddingTop: '5px' }}
      >
        {value?.sentAt
          ? `Rechnung versendet: ${moment(value?.sentAt).format('DD.MM.YYYY HH:mm')}`
          : ''}
      </Typography>

      <MessageBox
        open={!!notificationVisible}
        onClose={() => setNotificationVisible(undefined)}
        autoHide
        variant={notificationVisible?.variant}
        title={notificationVisible?.title}
        message={notificationVisible?.message}
        horizontal={undefined}
        small={undefined}
        vertical={undefined}
        onClick={undefined}
      />
    </Grid>
  )
}

export default BillingButton
