import React, { useEffect, useState, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLazyQuery } from '@apollo/client'
import { LOOKUP_TABLE_QUERY } from '../../../graphql/queries'
import Select from '../Select'

interface Item {
  value: string
  label: string
  [name: string]: any
}

export interface QuerySelectProps {
  onChange: (value: string | string[]) => void
  value?: string | string[]
  name?: string
  label?: string
  placeholder?: string
  query?: any
  variables?: any
  additionalSelectFields?: Item[]
  disabled?: boolean
  options?: any
  multiple?: boolean
  native?: boolean
  error?: boolean
  minWidth?: string | number
  maxWidth?: string | number
  helperText?: string
  showOnlyField?: { value: string; label?: string }
  id?: string
}

const QuerySelect = React.forwardRef<React.ReactNode, QuerySelectProps>(
  (
    {
      additionalSelectFields,
      disabled,
      name,
      query,
      options,
      onChange,
      multiple,
      native,
      ...props
    },
    ref
  ) => {
    const [fetchData, { data, loading, error }] = useLazyQuery(query || LOOKUP_TABLE_QUERY, {
      fetchPolicy: 'cache-first',
      variables: options?.variables
    })

    const methods = useFormContext()
    const { watch, setValue } = methods || {}
    const watchValue = options?.dependency ? watch(options?.dependency) : undefined
    const [dependentState, setDependentState] = useState<any>(() => watchValue)

    useEffect(() => {
      if (watchValue && watchValue !== dependentState && dependentState !== undefined) {
        console.debug(props.id, 'dependency changed, resetting value')
        setValue(props.id || '', '')
        options?.onChange?.('')
      }
    }, [watchValue, setValue, props.id, dependentState, options])

    const [entries, setEntries] = useState<Item[] | null>(null)
    const handleChange = (newValues: any) => {
      if (multiple && native) {
        const newSelected = [...(props.value || [])]
        const index = newSelected.indexOf(newValues)
        if (index === -1) {
          newSelected.push(newValues)
        } else {
          newSelected.splice(index, 1)
        }

        options?.onChange?.(newSelected)
        onChange(newSelected)
        setDependentState(watchValue)
      } else if (multiple && newValues.length) {
        const lastFound = additionalSelectFields?.find(
          field => field?.value === newValues?.[newValues.length - 1]
        )

        if (lastFound) {
          options?.onChange?.([lastFound.value])
          onChange([lastFound.value])
        } else {
          const returnVal = newValues.filter((value: string) =>
            additionalSelectFields?.find(field => field.value !== value)
          )
          options?.onChange?.(returnVal)
          onChange(returnVal)
        }
        // If the selected element has a truthy value on 'actionDecisionField' then execute the 'fieldAction'
      } else if (options?.actionDecisionField && options?.fieldAction) {
        if (
          entries?.find((item: any) => item.value === newValues)?.[options?.actionDecisionField]
        ) {
          options?.fieldAction?.()
        }
        options?.onChange?.(newValues)
        onChange(newValues)
      } else {
        options?.onChange?.(newValues)
        onChange(newValues)
      }
    }

    const getEntries = useCallback(() => {
      if (!data) {
        setEntries(null)
      } else {
        const { collection } = data?.[Object.keys(data)[0]]
        let newEntries = [
          ...(additionalSelectFields || []),
          ...collection?.filter((item: any) => {
            if (!name) return true
            return item.lookupType?.toLowerCase() === name.toLowerCase()
          })
        ]

        const seperatorIndex = options?.seperatorLocation?.(newEntries)

        newEntries = newEntries?.map((item: any) => ({
          label: item?.[options?.labelField] || item?.value,
          value: item?.[options?.valueField] || item?.value,
          isArchived: item?.archived,
          ...(options?.actionDecisionField
            ? { [options?.actionDecisionField]: item?.[options?.actionDecisionField] }
            : {})
        }))

        if (seperatorIndex > 0) newEntries.splice(seperatorIndex, 0, { isSeperator: true })
        setEntries(newEntries)
      }
    }, [options, name, additionalSelectFields, data])

    useEffect(() => {
      if (props.value && !entries) {
        fetchData()
      }
    }, [props.value, entries, fetchData])
    useEffect(() => {
      if (!entries && native) {
        fetchData()
      }
    }, [entries, native, fetchData])

    useEffect(() => {
      getEntries()
    }, [getEntries, data])

    if (error) throw error
    if (loading)
      return (
        <Select
          {...props}
          native={native}
          ref={ref}
          onClick={fetchData}
          items={[...(additionalSelectFields || [])]}
          onChange={handleChange}
          multiple={multiple}
          disabled={disabled}
        />
      )

    return (
      <Select
        {...props}
        native={native}
        items={entries as Item[]}
        onClick={fetchData}
        onChange={handleChange}
        multiple={multiple}
        disabled={disabled}
      />
    )
  }
)

export default QuerySelect
