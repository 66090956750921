import React, { useEffect } from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material'
import { useEditor } from '../../../utils/hooks'
import { Header, Footer } from './components'

interface EditorModalProps {
  open: boolean
  header?: object
  body?: React.ReactNode
  footer?: object
  setOpen?: (open: boolean) => void
  abortCallback?: () => void
  handleSave: () => void
}

const EditorModal: React.FC<EditorModalProps> = ({
  header,
  body,
  footer,
  open = false,
  setOpen,
  abortCallback,
  handleSave
}) => {
  const { setIsEditorModalOpen } = useEditor()

  useEffect(() => {
    setIsEditorModalOpen(open)
  }, [open, setIsEditorModalOpen])

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          bottom: '0px',
          margin: '0px',
          padding: '0px',
          borderRadius: '0px'
        }
      }}
    >
      <DialogTitle>
        <Header {...header} />
      </DialogTitle>
      <DialogContent dividers>{body}</DialogContent>
      <DialogActions>
        <Footer
          {...footer}
          abortCallback={() => {
            setOpen?.(false)
            abortCallback?.()
          }}
          handleSave={handleSave}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EditorModal
