import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { OutlinedCheck } from '../../../assets/icons'
import { Task } from '../../../utils/fixtures'
import { Button, DatePicker, Input } from '../../atoms'

export interface TaskFormProps {
  task?: Task
  isEdit?: boolean
  addOrSaveTask: (task?: Task) => void
}

const TaskForm = ({ task: propTask, addOrSaveTask, isEdit }: TaskFormProps): JSX.Element => {
  const { t } = useTranslation('common')
  const [task, setTask] = useState<Task | undefined>()
  useEffect(() => {
    setTask(propTask)
  }, [propTask, setTask])
  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value }
    } = event
    setTask(oldTask => ({ ...oldTask, title: value }) as Task)
  }

  const onDueDateChange = (newValue: Date | null): void => {
    setTask(vals => ({ ...vals, dueDate: newValue ? newValue.toString() : null }) as Task)
  }

  const handleNewOrSavedTask = () => {
    if (task?.title) {
      addOrSaveTask?.(task)
      setTask(undefined)
    }
  }

  return (
    <form id="add-task-form">
      <Grid container spacing={2} sx={{ py: 1 }}>
        <Grid item xs={12} lg={5}>
          <Input
            fullWidth
            name="title"
            required
            onChange={onTitleChange}
            value={task?.title || ''}
            label={t('description')}
          />
        </Grid>
        <Grid item xs={12} lg="auto">
          <DatePicker
            type="datetime"
            label={t('dueDate')}
            onChange={onDueDateChange}
            value={task?.dueDate ? new Date(task?.dueDate) : null}
          />
        </Grid>
        <Grid item xs={12} lg="auto" sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            form="add-task-form"
            onClick={handleNewOrSavedTask}
            type="button"
            disabled={!task?.title}
            startIcon={isEdit ? <OutlinedCheck /> : <AddCircleOutline />}
          >
            {isEdit ? t('save') : t('add')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default TaskForm
