import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import { ME_QUERY } from '../../graphql/queries'
import { Processing } from '../fixtures'

const initialValues = {
  me: {},
  refetch: () => {}
}

type ContextType = {
  processing?: Processing
  me?: any
  setMe: Dispatch<SetStateAction<any>>
  refetch: (variables?: Partial<{ id: string }> | undefined) => Promise<ApolloQueryResult<any>>
}
const context = {
  me: initialValues.me,
  setMe: () => {},
  refetch: () => new Promise(() => {})
} as ContextType

const ProfilContext = React.createContext<ContextType>(context)
interface ProfilContextProviderProps {
  children: JSX.Element
}

export const ProfilContextProvider = ({ children }: ProfilContextProviderProps) => {
  const [me, setMe] = useState<any>(initialValues.me)

  const { data, refetch } = useQuery(ME_QUERY)
  useEffect(() => {
    setMe({ ...data?.me })
  }, [data, setMe])

  return (
    <ProfilContext.Provider
      value={{
        me,
        setMe,
        refetch
      }}
    >
      {children}
    </ProfilContext.Provider>
  )
}

export default ProfilContext
