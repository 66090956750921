import React from 'react'
import PropTypes from 'prop-types'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { Button } from '@mui/material'

const FileItemButton = props => (
  <Button
    {...props}
    sx={theme => ({
      boxShadow: `0px 2px 12px 0px ${theme.palette?.grey?.A500}`,
      border: theme.palette?.grey[50],
      backgroundColor: 'white',
      borderRadius: theme.shape?.borderRadius,
      color: theme.palette?.common?.black,
      '&:hover': {
        backgroundColor: theme.palette?.grey[100]
      }
    })}
  />
)

const FileItem = ({ file }) =>
  file ? (
    <FileItemButton href={file.path} startIcon={<AttachmentIcon />}>
      {file.name}
    </FileItemButton>
  ) : null
FileItem.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string
  })
}
FileItem.defaultProps = {
  file: null
}

export default FileItem
