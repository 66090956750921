import React from 'react'
import PropTypes from 'prop-types'
import {
  BarChart as RBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

const BarChart = ({
  height,
  data,
  dataOptions,
  stacked,
  barSize,
  barBorderRadius,
  legendPayload
}) => (
  <ResponsiveContainer width="100%" height={height}>
    <RBarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend payload={legendPayload} />
      {dataOptions?.map((dataOption, index) => {
        const hasRadius = !stacked || (stacked && index === dataOptions.length - 1)
        return (
          <Bar
            name={dataOption.name || dataOption.dataKey}
            key={dataOption.dataKey}
            dataKey={dataOption.dataKey}
            fill={dataOption.fill}
            stroke={stacked ? '#fff' : undefined}
            stackId={stacked ? 1 : undefined}
            barSize={barSize || undefined}
            radius={hasRadius ? barBorderRadius : undefined}
          />
        )
      })}
    </RBarChart>
  </ResponsiveContainer>
)
BarChart.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array.isRequired,
  dataOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      dataKey: PropTypes.string.isRequired,
      fill: PropTypes.string.isRequired
    })
  ).isRequired,
  stacked: PropTypes.bool,
  barSize: PropTypes.number,
  barBorderRadius: PropTypes.arrayOf(PropTypes.number),
  legendPayload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      color: PropTypes.string,
      id: PropTypes.string,
      formatter: PropTypes.any,
      inactive: PropTypes.bool,
      legendIcon: PropTypes.any,
      type: PropTypes.string,
      payload: PropTypes.array
    })
  )
}
BarChart.defaultProps = {
  height: 300,
  stacked: false,
  barSize: undefined,
  barBorderRadius: [5, 5, 0, 0],
  legendPayload: undefined
}

export default BarChart
