import React from 'react'
import PropTypes from 'prop-types'
import { DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import Footer from './components/Footer'
import Header from './components/Header'

const ColoredPaper = styled(Paper)(({ definedColor, theme }) => {
  switch (definedColor) {
    case 'greenSofter':
      return {
        backgroundColor: theme.palette.greenSofter,
        '&>.MuiInputBase-root.Mui-disabled': { backgroundColor: 'red' }
      }
    case 'blueSoft':
      return {
        backgroundColor: theme.palette.blueSoft
      }
    case 'redSoft':
      return {
        backgroundColor: theme.palette.redSoft
      }
    case 'yellowSoft':
      return {
        backgroundColor: theme.palette.yellowSoft
      }
    default:
      return {
        backgroundColor: theme.palette.greenSofter,
        '&>.MuiInputBase-root.Mui-disabled': { backgroundColor: 'red' }
      }
  }
})

const PaperItem = ({ header, body, footer, color }) => (
  <ColoredPaper definedColor={color}>
    <DialogTitle>
      <Header {...header} />
    </DialogTitle>
    <DialogContent dividers>{body}</DialogContent>
    <DialogActions>
      <Footer {...footer} />
    </DialogActions>
  </ColoredPaper>
)

PaperItem.propTypes = {
  header: PropTypes.object,
  body: PropTypes.node,
  footer: PropTypes.object,
  color: PropTypes.oneOf(['greenSofter', 'blueSoft', 'redSoft', 'yellowSoft'])
}
PaperItem.defaultProps = {
  header: null,
  body: null,
  footer: null,
  color: 'greenSofter'
}
export default PaperItem
