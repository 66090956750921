import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { BrandLogo, Button, Input } from '../../atoms'

const LoginCard = ({ onSubmit }) => {
  const { t } = useTranslation('auth')

  const loginSchema = Yup.object().shape({
    password: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalidEmail')).required(t('required'))
  })
  return (
    <Box
      sx={{
        backgroundColor: 'common.white',
        boxShadow: `0 4px 20px 0 grey`,
        borderRadius: '4px',
        paddingBottom: '5px',
        maxWidth: '520px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ lineHeight: 1, backgroundColor: 'petrol', borderRadius: '4px 4px 0 0' }} p={3}>
        <BrandLogo height="34px" type="withText" />
      </Box>
      <Box m={4}>
        <Formik onSubmit={onSubmit} validationSchema={loginSchema} initialValues={{}}>
          {({ handleChange, handleSubmit, values, errors }) => (
            <form id="login-form" name="login-form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Typography variant="H1">{t('login')}</Typography>
                </Grid>
                <Grid xs={12} item>
                  <Input
                    fullWidth
                    onChange={handleChange}
                    value={values.email}
                    label={t('email')}
                    name="email"
                    error={!!errors?.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Input
                    fullWidth
                    onChange={handleChange}
                    value={values.password}
                    label={t('password')}
                    name="password"
                    error={!!errors?.password}
                    helperText={errors.password}
                    type="password"
                  />
                </Grid>
                <Grid item>
                  <Button color="primary" type="submit" form="login-form">
                    {t('loginAction')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}
LoginCard.propTypes = {
  onSubmit: PropTypes.func
}
LoginCard.defaultProps = {
  onSubmit: () => {}
}

export default LoginCard
