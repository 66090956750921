import React, { useState } from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionActions'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion
    // performance optimization
    TransitionProps={{ unmountOnExit: true }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(() => ({
  '&:before': {
    display: 'none'
  }
}))

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  paddingLeft: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  paddingLeft: 0,
  paddingRight: 0,
  justifyContent: 'flex-start'
}))

const Accordion = ({ accordionElements: propAccordionElements, ...props }: InputProps) => {
  const accordionElements = propAccordionElements ? [...propAccordionElements] : []
  const [activeValue, setActiveValue] = useState<string | boolean>(props.defaultValue as string)
  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setActiveValue(isExpanded ? panel : false)
  }
  return (
    <div>
      {accordionElements.map(({ summary, details, id }) => (
        <StyledAccordion {...props} expanded={activeValue === id} onChange={handleChange(id)}>
          <StyledAccordionSummary key={id} aria-controls={`${id}-content`} id={id}>
            <Typography variant="h6">{summary}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>{details}</StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  )
}

export interface InputProps extends Omit<AccordionProps, 'children'> {
  accordionElements?: AccordionElement[]
}

export interface AccordionElement {
  summary: string
  details: JSX.Element
  id: string
  expanded?: boolean
}

export default Accordion
