import React, { ElementType } from 'react'
import { Typography } from '@mui/material'
import { Variant as MVariant } from '@mui/material/styles/createTypography'

type Variant = MVariant | 'tabBarCurrent' | 'tabBarDefault' | 'subtitle'

interface TextProps {
  variant?: Variant
  component?: string
  children?: React.ReactNode
  className?: string
  sx?: object
}

const variantMapping: { [key in Variant]: MVariant } = {
  body1: 'body1',
  subtitle: 'h4',
  tabBarDefault: 'h5',
  tabBarCurrent: 'h6',
  button: 'button',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'subtitle1',
  subtitle2: 'subtitle2',
  body2: 'body2',
  caption: 'caption',
  overline: 'overline'
}

const Text: React.FC<TextProps> = ({ children, component, variant, className, sx }) => (
  <Typography
    component={component as ElementType}
    variant={variantMapping[variant || 'body1']}
    className={className}
    sx={sx}
  >
    {children}
  </Typography>
)

Text.defaultProps = {
  variant: 'body1',
  component: 'p',
  children: null,
  className: undefined,
  sx: undefined
}

export default Text
