import React from 'react'
import { AppBar as MAppBar } from '@mui/material'

export interface AppBarProps {
  children?: React.ReactNode
  backgroundColor?: string
  shadow?: boolean
  position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky'
}

const AppBar: React.FC<AppBarProps> = ({
  children = null,
  backgroundColor = 'white',
  shadow = false,
  position = 'relative'
}) => (
  <MAppBar
    sx={{
      ...(!shadow && { boxShadow: 'none' }),
      backgroundColor
    }}
    position={position}
  >
    {children}
  </MAppBar>
)

export default AppBar
