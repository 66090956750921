import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Theme } from '@mui/material/styles'

export interface DatePickerWrapperProps {
  $fullWidth?: boolean
  theme?: Theme & { fontFamily: string }
}

const DatePickerWrapper = styled.div<DatePickerWrapperProps>(
  props => css`
    display: flex;
    flex-direction: row;

    ${props.$fullWidth &&
    css`
      .react-datepicker-wrapper {
        width: 100%;
      }
    `}

    .react-datepicker-popper {
      z-index: 1202;
    }

    .react-datepicker {
      border: none;
      box-shadow: 0 4px 20px 0 ${props.theme.palette.grey[500]};
      font-family: ${props.theme.fontFamily};
      background: white;
      padding-top: 8px;

      & .react-datepicker__navigation {
        top: 18px;
        outline: none;
      }

      & .react-datepicker__header {
        background: white;
        border: none;
      }

      & .react-datepicker__current-month {
        color: ${props.theme.palette.black};
        font-weight: normal;
      }

      & .react-datepicker__day--keyboard-selected {
        background-color: ${props.theme.palette.grey[300]};
      }

      & .react-datepicker__day-name {
        color: ${props.theme.palette.grey[300]};
      }

      & .react-datepicker__day--in-selecting-range {
        color: ${props.theme.palette.common.black};
        background-color: ${props.theme.palette.grey[50]};
      }

      & .react-datepicker__day {
        border-radius: 50%;
        outline: none;
      }

      & .react-datepicker__day:hover {
        color: ${props.theme.palette.common.black};
        background-color: ${props.theme.palette.grey[50]};
      }

      & .react-datepicker__day--selected {
        color: ${props.theme.palette.common.white};
        background-color: ${props.theme.palette.common.black};
      }

      & .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selecting-range-end {
        color: ${props.theme.palette.common.black};
        background-color: ${props.theme.palette.grey[100]};
      }

      & .react-datepicker__day--in-range {
        color: ${props.theme.palette.common.black};
        background-color: ${props.theme.palette.grey[100]};
      }

      & .react-datepicker__day--range-start,
      .react-datepicker__day--range-end {
        color: ${props.theme.palette.common.white};
        background-color: ${props.theme.palette.common.black};
      }

      & .react-datepicker__day--in-range:hover {
        color: ${props.theme.palette.common.white};
        background-color: ${props.theme.palette.common.black};
      }
    }
  `
)

export default DatePickerWrapper
