import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { DatePicker, Input, Rating, Select, QuerySelect, CheckBox } from '../../atoms'

const CheckableFormItem = React.memo(props => {
  const { t } = useTranslation('caseProperty', 'common')
  const { query, queryOptions, label, disabled, type, name, helperText } = props
  const { control } = useFormContext()

  const [checked, setChecked] = useState(false)

  const handleFieldChangeFromEvent = ({ target: { value } }, onChange) => {
    setChecked(true)
    onChange(value)
  }

  const handleFieldChangeFromValue = (value, onChange) => {
    setChecked(true)
    onChange(value)
  }

  const dateComponent = (onChange, value, ref) => (
    <DatePicker
      ref={ref}
      onChange={e => handleFieldChangeFromValue(e, onChange)}
      value={value}
      label={label}
      type={type}
      fullWidth
      disabled={disabled}
      helperText={helperText}
    />
  )
  const dropdownComponent = (onChange, value, ref) => (
    <QuerySelect
      ref={ref}
      onChange={e => handleFieldChangeFromValue(e, onChange)}
      value={value}
      label={label}
      control={control}
      name={queryOptions?.variables?.filter?.lookupType || undefined}
      query={query}
      options={queryOptions}
      minWidth="100%"
      disabled={disabled}
      helperText={helperText}
    />
  )
  const caseStates = [
    {
      value: 'in_capture',
      label: t('inCapture')
    },
    {
      value: 'open',
      label: t('open')
    },
    {
      value: 'coverage_request',
      label: t('coverageRequest')
    },
    {
      value: 'in_progress',
      label: t('inProgress')
    },
    {
      value: 'jump_off',
      label: t('jumpOff')
    },
    {
      value: 'completed',
      label: t('completed')
    },
    {
      value: 'checked',
      label: t('checked')
    },
    {
      value: 'settled',
      label: t('settled')
    }
  ]
  const caseStatesComponent = (onChange, value, ref) => (
    <Select
      ref={ref}
      value={value}
      onChange={val => handleFieldChangeFromValue(val, onChange)}
      minWidth="100%"
      label={t('caseState')}
      items={caseStates}
      disabled={disabled}
      helperText={helperText}
    />
  )
  const processingStates = [
    {
      value: 'open',
      label: t('open')
    },
    {
      value: 'assigned',
      label: t('assigned')
    },
    {
      value: 'accepted',
      label: t('accepted')
    },
    {
      value: 'blocked',
      label: t('blocked')
    },
    {
      value: 'first_contacted',
      label: t('firstContacted')
    },
    {
      value: 'coverage_check',
      label: t('coverageCheck')
    },
    {
      value: 'in_progress',
      label: t('inProgress')
    },
    {
      value: 'completed',
      label: t('completed')
    },
    {
      value: 'checked',
      label: t('checked')
    },
    {
      value: 'settled',
      label: t('settled')
    },
    {
      value: 'transfer',
      label: t('transfer')
    },
    {
      value: 'jump_off',
      label: t('jumpOff')
    }
  ]
  const processingStatesComponent = (onChange, value, ref) => (
    <Select
      ref={ref}
      value={value}
      onChange={val => handleFieldChangeFromValue(val, onChange)}
      minWidth="100%"
      label={t('processingState')}
      items={processingStates}
      disabled={disabled}
      helperText={helperText}
    />
  )
  const ratingComponent = (onChange, value, ref) => (
    <Rating ref={ref} value={value} onChange={val => handleFieldChangeFromValue(val, onChange)} />
  )
  const booleanComponent = (onChange, value, ref) => (
    <Select
      ref={ref}
      value={value}
      onChange={val => handleFieldChangeFromValue(val, onChange)}
      minWidth="100%"
      label={label}
      items={[
        {
          label: t('yes'),
          value: true
        },
        {
          label: t('no'),
          value: false
        }
      ]}
      disabled={disabled}
      helperText={helperText}
    />
  )
  const textfieldComponent = (onChange, value, ref) => (
    <Input
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      multiline
      rows={5}
      fullWidth
      type={type}
      label={label}
      disabled={disabled}
      helperText={helperText}
    />
  )
  const inputComponent = (onChange, value, ref) => (
    <Input
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      fullWidth
      type={type}
      label={label}
      disabled={disabled}
      helperText={helperText}
    />
  )

  const checkBoxComponent = (onChange, value, ref) => (
    <CheckBox
      ref={ref}
      checked={value}
      value={value}
      onChange={val => handleFieldChangeFromValue(val, onChange)}
      label={label}
      disabled={disabled}
      helperText={helperText}
    />
  )

  const dateTypes = ['date', 'datetime']
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <Grid container alignItems="center">
          <Grid item>
            <CheckBox
              checked={checked}
              onChange={newChecked => {
                if (newChecked && dateTypes.includes(type)) {
                  onChange(null)
                }
                if (!newChecked) {
                  onChange('')
                }
                setChecked(newChecked)
              }}
            />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            {dateTypes.includes(type) && dateComponent(onChange, value, ref)}
            {type === 'dropdown' && dropdownComponent(onChange, value, ref)}
            {type === 'casestates' && caseStatesComponent(onChange, value, ref)}
            {type === 'processingstates' && processingStatesComponent(onChange, value, ref)}
            {type === 'rating' && ratingComponent(onChange, value, ref)}
            {type === 'boolean' && booleanComponent(onChange, value, ref)}
            {type === 'textfield' && textfieldComponent(onChange, value, ref)}
            {type === 'checkbox' && checkBoxComponent(onChange, value, ref)}
            {(type === 'money' || type === 'default') && inputComponent(onChange, value, ref)}
          </Grid>
        </Grid>
      )}
    />
  )
})
CheckableFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  query: PropTypes.object,
  queryOptions: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  helperText: PropTypes.string
}
CheckableFormItem.defaultProps = {
  query: undefined,
  queryOptions: undefined,
  label: null,
  disabled: false,
  type: 'default',
  helperText: undefined
}

export default CheckableFormItem
