import React from 'react'
import { useHistory } from 'react-router'
import MenuSharpIcon from '@mui/icons-material/MenuSharp'
import { SpeedDial, SpeedDialAction } from '@mui/material'

export interface IActions {
  name: string
  icon: React.ReactNode
  onClick: () => void
}

export interface IItems {
  icon: React.ReactNode
  label: string
  isNavItem: boolean
  to: string
  externalLink?: boolean
  permissionId: string
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void
}

export interface IMobileMenu {
  actions?: IActions[]
  landscape?: boolean
  icon?: React.ReactNode
  disabled?: string[]
  items: IItems[]
}

const MobileMenu: React.FC<IMobileMenu> = ({ actions, landscape, icon, disabled, items }) => {
  const history = useHistory()
  const mobileMenuItems = React.useMemo(
    () => [
      ...(actions || []),
      ...items
        .filter(m => !disabled?.includes(m.permissionId))
        .map(m => ({
          name: m.label,
          icon: m.icon,
          onClick: () => {
            if (m.isNavItem) history.push(m.to)
            else if (m.externalLink) window.open(m.to)
          }
        }))
        .reverse()
    ],
    [disabled, items, actions, history]
  )
  return (
    <SpeedDial
      direction={landscape ? 'left' : 'up'}
      ariaLabel="mobile-menu"
      icon={icon || <MenuSharpIcon />}
      sx={{ position: 'fixed', bottom: 16, right: 16 }}
    >
      {mobileMenuItems.map(action => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  )
}

export default MobileMenu
