import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Box, Dialog, DialogContent, DialogActions, Typography, Grid } from '@mui/material'
import { OutlinedCross } from '../../../assets/icons'
import { REQUEST_COST_COVERAGE } from '../../../graphql/mutations'
import { Button, Input } from '../../atoms'
import MessageBox from '../MessageBox'

interface IDialogContent {
  title: string
  subtitle: string
  message: string
  variables: any
  callback(variables?: any): void
}

interface Attributes {
  address: string
  claimNumber: string
  claimDate: string
  disputeValue?: string
  legalArea: string
  mobileNumber?: string
  name: string
  phoneNumber?: string
  comments?: string
  commentsLegalAdvice?: string
  contactPersonName?: string
  contactPersonPhoneNumber?: string
  contactPersonMobileNumber?: string
  relationToPolicyholder?: string
}

interface INotification {
  variant: 'success' | 'error' | 'warning'
  title: string
  message: string
}

interface RequestCoverageButtonProps {
  options?: any
  label: string
  value?: any[]
  disabled?: boolean
  dialogContent?: IDialogContent
  helperText?: string
}

const RequestCoverageButton: React.FC<RequestCoverageButtonProps> = ({
  label,
  value,
  disabled,
  options,
  dialogContent,
  helperText
}) => {
  const { t } = useTranslation(['common', 'requestCoverage'])
  const [attributes, setAttributes] = useState<Attributes>(dialogContent?.variables)
  useEffect(() => {
    setAttributes(dialogContent?.variables)
  }, [dialogContent?.variables, dialogContent?.variables?.legalArea])
  const [open, setOpen] = useState(false)
  const isFormValid = useCallback(() => {
    let valid = true
    if (!(attributes.name && attributes.name.length > 0)) {
      valid = false
    }
    if (!(attributes.address && attributes.address.length > 0)) {
      valid = false
    }
    if (!(attributes.commentsLegalAdvice && attributes.commentsLegalAdvice.length > 0)) {
      valid = false
    }
    if (!(attributes.claimNumber && attributes.claimNumber.length > 0)) {
      valid = false
    }
    if (!(attributes.claimDate && attributes.claimDate.length > 0)) {
      valid = false
    }
    if (!(attributes.legalArea && attributes.legalArea.length > 0)) {
      valid = false
    }

    return valid
  }, [
    attributes?.name,
    attributes?.address,
    attributes?.commentsLegalAdvice,
    attributes?.claimNumber,
    attributes?.claimDate,
    attributes?.legalArea
  ])

  const [formValid, setFormValid] = useState(isFormValid())
  useEffect(() => {
    setFormValid(isFormValid())
  }, [isFormValid])
  const [notificationVisible, setNotificationVisible] = useState<INotification | undefined>()
  const [submitMutation] = useMutation(REQUEST_COST_COVERAGE, {
    onCompleted: () => {
      setOpen(false)
      setNotificationVisible({
        variant: 'success',
        title: t('success'),
        message: t('requestCoverage:success')
      })
      dialogContent?.callback?.(attributes)
    },
    onError: error => {
      setOpen(false)
      setNotificationVisible({ variant: 'error', title: t('error'), message: error?.message })
    }
  })
  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} fullWidth disabled={disabled}>
        {label}
      </Button>

      {helperText && (
        <Typography
          variant="caption"
          sx={{ paddingTop: 1, display: 'flex', margin: '0px 14px', width: '100%' }}
        >
          {helperText}
        </Typography>
      )}
      <Typography
        variant="caption"
        sx={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '5px' }}
      >
        {value?.[0]}
      </Typography>
      <Dialog open={open} maxWidth="lg" data-testid="sms-modal">
        <DialogContent>
          <>
            <Typography variant="h5">{dialogContent?.title}</Typography>
            <Typography variant="caption" sx={{ pt: 1 }}>
              {dialogContent?.subtitle}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.claimNumber}
                  label={t('caseProperty:claimNumber')}
                  fullWidth
                  required
                  onChange={({ target: { value: claimNumber } }) =>
                    setAttributes((vars: any) => ({ ...vars, claimNumber }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.claimDate}
                  label={t('caseProperty:claimDate')}
                  fullWidth
                  required
                  onChange={({ target: { value: claimDate } }) =>
                    setAttributes((vars: any) => ({ ...vars, claimDate }))
                  }
                />
              </Grid>
              <Grid item md={12}>
                <Typography variant="h6">{t('caseProperty:policyholder')}</Typography>
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.name}
                  label={t('requestCoverage:name')}
                  required
                  fullWidth
                  onChange={({ target: { value: name } }) =>
                    setAttributes((vars: any) => ({ ...vars, name }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.address}
                  label={t('requestCoverage:address')}
                  required
                  fullWidth
                  onChange={({ target: { value: address } }) =>
                    setAttributes((vars: any) => ({ ...vars, address }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.phoneNumber}
                  label={t('caseProperty:phoneNumber')}
                  fullWidth
                  onChange={({ target: { value: phoneNumber } }) =>
                    setAttributes((vars: any) => ({ ...vars, phoneNumber }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.mobileNumber}
                  label={t('caseProperty:mobileNumber')}
                  fullWidth
                  onChange={({ target: { value: mobileNumber } }) =>
                    setAttributes((vars: any) => ({ ...vars, mobileNumber }))
                  }
                />
              </Grid>
              <Grid item md={12}>
                <Typography variant="h6">{t('caseProperty:contactPerson')}</Typography>
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.contactPersonName}
                  label={t('requestCoverage:contactPersonName')}
                  fullWidth
                  onChange={({ target: { value: contactPersonName } }) =>
                    setAttributes((vars: any) => ({ ...vars, contactPersonName }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.relationToPolicyholder}
                  label={t('requestCoverage:relationToPolicyholder')}
                  fullWidth
                  onChange={({ target: { value: relationToPolicyholder } }) =>
                    setAttributes((vars: any) => ({ ...vars, relationToPolicyholder }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.contactPersonPhoneNumber}
                  label={t('caseProperty:phoneNumber')}
                  fullWidth
                  onChange={({ target: { value: contactPersonPhoneNumber } }) =>
                    setAttributes((vars: any) => ({ ...vars, contactPersonPhoneNumber }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.contactPersonMobileNumber}
                  label={t('caseProperty:mobileNumber')}
                  fullWidth
                  onChange={({ target: { value: contactPersonMobileNumber } }) =>
                    setAttributes((vars: any) => ({ ...vars, contactPersonMobileNumber }))
                  }
                />
              </Grid>
              <Grid item md={12}>
                <Typography variant="h6">{t('caseProperty:caseInformations')}</Typography>
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.legalArea}
                  label={t('requestCoverage:legalArea')}
                  disabled
                  required
                  fullWidth
                  onChange={({ target: { value: legalArea } }) =>
                    setAttributes((vars: any) => ({ ...vars, legalArea }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  type="money"
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.disputeValue}
                  label={t('caseProperty:disputeValue')}
                  fullWidth
                  onChange={({ target: { value: disputeValue } }) =>
                    setAttributes((vars: any) => ({ ...vars, disputeValue }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.comments}
                  label={t('caseProperty:caseComments')}
                  multiline
                  minRows={5}
                  fullWidth
                  onChange={({ target: { value: comments } }) =>
                    setAttributes((vars: any) => ({ ...vars, comments }))
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Input
                  sx={{ whiteSpace: 'pre-line' }}
                  value={attributes?.commentsLegalAdvice}
                  label={t('caseProperty:caseCommentsLegalAdvice')}
                  required
                  multiline
                  minRows={5}
                  fullWidth
                  onChange={({ target: { value: commentsLegalAdvice } }) =>
                    setAttributes((vars: any) => ({ ...vars, commentsLegalAdvice }))
                  }
                />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Box sx={{ marginRight: '39px', marginBottom: '42px' }}>
            <Button
              color="secondary"
              sx={{ marginRight: '15px' }}
              onClick={() => setOpen(false)}
              startIcon={<OutlinedCross />}
            >
              {t('common:abort')}
            </Button>
            <Button
              color="primary"
              disabled={!formValid}
              onClick={() => {
                if (formValid) {
                  submitMutation({
                    variables: {
                      ...options?.variables,
                      attributes
                    }
                  })
                }
              }}
            >
              {t('sms:sendNow')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <MessageBox
        open={!!notificationVisible}
        onClose={() => setNotificationVisible(undefined)}
        autoHide
        variant={notificationVisible?.variant}
        title={notificationVisible?.title}
        message={notificationVisible?.message}
      />
    </>
  )
}

RequestCoverageButton.defaultProps = {
  value: [],
  disabled: false,
  options: undefined,
  dialogContent: undefined,
  helperText: undefined
}

export default RequestCoverageButton
