import React, { ReactElement } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Button } from '../../atoms'

interface ButtonProps {
  color: 'primary' | 'secondary'
  onClick: () => void
  label: ReactElement<any, any> | string
}

export interface ModalProps {
  open: boolean
  title: string
  content: string
  buttons: ButtonProps[]
}
const Modal: React.FC<ModalProps> = ({ title, content, buttons, open }) => (
  <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      {buttons.map(button => (
        <Button color={button.color} onClick={button.onClick}>
          {button.label}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
)

export default Modal
