import React from 'react'
import { ReactComponent as LogoWithText } from '../../../assets/icons/logo-text.svg'
import { ReactComponent as LogoVertical } from '../../../assets/icons/logo-vertical.svg'
import { ReactComponent as LogoHorizontal } from '../../../assets/icons/logo.svg'

interface InputProps {
  type: 'default' | 'vertical' | 'withText'
  width: number | string | undefined
  height: number | string | undefined
}

const BrandLogo = ({ type, width, height }: InputProps) => {
  let component = <LogoHorizontal width={width} height={height} />
  switch (type) {
    case 'vertical':
      component = <LogoVertical width={width} height={height} />
      break
    case 'withText':
      component = <LogoWithText width={width} height={height} />
      break
    default:
      break
  }
  return component
}

export default BrandLogo
