import React, { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardActions, CardContent, Popover, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { Button } from '../../atoms'

export interface IPopOverConfirmDialog {
  onConfirm: () => void
  confirmButtonSx?: SxProps<Theme>
  confirmButtonText?: string
  abortButtonSx?: SxProps<Theme>
  abortButtonText?: string
  message?: string | React.ReactNode
  children?: React.ReactNode
}

const PopOverConfirmDialog: FC<IPopOverConfirmDialog> = ({
  onConfirm = () => {},
  confirmButtonSx,
  confirmButtonText,
  abortButtonText,
  abortButtonSx,
  message,
  children
}) => {
  const { t } = useTranslation('common')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleConfirmDelete = () => {
    onConfirm()
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const childWithProps = React.isValidElement(children)
    ? // @ts-ignore
      React.cloneElement(children, { onClick: handleClick })
    : children
  //       #
  //        const clone = React.cloneElement(<Demo />, {
  //     click: () => {
  //       alert("You clicked Demo :)");
  //     }
  //   });
  return (
    <>
      {childWithProps || (
        <Button onClick={handleClick} sx={theme => ({ backgroundColor: theme.palette.error.main })}>
          delete
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Card sx={{ p: 2 }}>
          <CardContent>
            <Typography>{message || t('defaultConfirmMessage')}</Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-evenly' }}>
            <Button sx={confirmButtonSx} onClick={handleConfirmDelete}>
              {confirmButtonText || t('yes')}
            </Button>
            <Button sx={abortButtonSx} onClick={handleClose}>
              {abortButtonText || t('no')}
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  )
}

export default PopOverConfirmDialog
