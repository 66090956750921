import React from 'react'
import { LoadingButton as MuiButton } from '@mui/lab'
import { ButtonProps as MuiButtonProps } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import colors from '../../../utils/colors'

const { blueSoft } = colors

export interface ButtonProps extends Omit<MuiButtonProps, 'component'> {
  variant?: 'contained' | 'outlined' | 'text'
  color?: 'primary' | 'secondary'
  children?: JSX.Element | string
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  disabled?: boolean
  component?: React.ElementType<any> | undefined
  to?: { pathname: string; search: string } | string
  type?: 'button' | 'submit' | 'reset'
  onClick?: (target: any) => void
  className?: string
  form?: string
  sx?: SxProps<Theme>
  fullWidth?: boolean
  loading?: boolean
  loadingPosition?: 'start' | 'center' | 'end'
}
const ExtendedButton: React.FC<ButtonProps> = <T extends React.ElementType>(
  props: ButtonProps & { component?: T }
) => <MuiButton {...props} />

const PrimaryButton = styled(ExtendedButton)(({ theme }) => ({
  flex: '0 0 auto',
  backgroundColor: theme.palette.petrol,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
    boxShadow: 'none'
  },
  '&:disabled': {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[500],
    boxShadow: 'none',
    cursor: 'not-allowed',
    pointerEvents: 'inherit'
  },
  '&:disabled:hover': {
    backgroundColor: theme.palette.grey[300]
  },
  '&:focus': {
    boxShadow: `0 0 0 1px ${theme.palette.common.white}, 0 0 0 3px ${blueSoft}`
  }
}))

const SecondaryButton = styled(ExtendedButton)(({ theme }) => ({
  flex: '0 0 auto',
  color: theme.palette.common.black,
  backgroundColor: theme.palette.secondary.main,
  '&:focus': {
    border: '1px solid white',
    boxShadow: `0 0 0 1px ${theme.palette.common.white}, 0 0 0 3px ${blueSoft}`
  },
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none'
  },
  '&:active': {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[200]
  },
  '&:disabled': {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[500],
    boxShadow: 'none',
    cursor: 'not-allowed',
    pointerEvents: 'inherit'
  },
  '&:disabled:hover': {
    backgroundColor: theme.palette.grey[300]
  }
}))

const Button = ({
  children,
  color,
  variant,
  startIcon,
  endIcon,
  disabled,
  type,
  onClick,
  className,
  form,
  to,
  component,
  sx,
  fullWidth,
  loading,
  ...props
}: ButtonProps) => {
  if (color === 'primary') {
    return (
      <PrimaryButton
        fullWidth={fullWidth}
        variant={variant}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled}
        type={type}
        component={component}
        onClick={onClick}
        to={to}
        sx={sx}
        form={form}
        loading={loading}
        {...props}
      >
        {children}
      </PrimaryButton>
    )
  }
  return (
    <SecondaryButton
      fullWidth={fullWidth}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      type={type}
      component={component}
      onClick={onClick}
      to={to}
      sx={sx}
      form={form}
      loading={loading}
      {...props}
    >
      {children}
    </SecondaryButton>
  )
}
Button.defaultProps = {
  children: 'Button',
  variant: 'contained',
  color: 'primary',
  onClick: () => {}
}

export default Button
