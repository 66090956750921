import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import { OutlinedAdd } from '../../../assets/icons'
import { useLocationChange } from '../../../utils/hooks'
import Button from '../Button'
import { AppBar, Tab, Tabs, TabPanel } from './components'

const a11yProps = (index: number) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`
})

type ITabKey = string | number

export interface ITab {
  key: ITabKey
  label: string
  content: React.ReactElement
}

interface TabbedContentProps {
  tabs: ITab[]
  scrollable: boolean
  onAdd: () => any
  addButtonLabel: string
  urlKey?: string
  defaultActive: any
  startAddon: React.ReactElement
  isUrlParameter: boolean
  extra: React.ReactElement
  setActiveTab: (newTabKey: ITabKey) => any
}

const TabbedContent: React.FC<TabbedContentProps> = ({
  tabs,
  scrollable,
  onAdd,
  addButtonLabel,
  defaultActive,
  startAddon,
  isUrlParameter,
  urlKey = 'tab',
  extra,
  setActiveTab
}) => {
  const fallbackTab = defaultActive || tabs?.[0]?.key || 0
  const [value, setValue] = useState<ITabKey>(fallbackTab)

  const history = useHistory()
  const { pathname } = useLocation()

  const handleChange = (event: any, newValue: ITabKey) => {
    if (isUrlParameter) {
      const queryStrings = queryString.parse(history.location.search)
      queryStrings[urlKey.toString()] = newValue.toString()
      history.push({
        pathname,
        search: queryString.stringify(queryStrings)
      })
    } else {
      if (setActiveTab) {
        setActiveTab(newValue)
      }
      setValue(newValue)
    }
  }

  const routeToTab = (location: any) => {
    if (isUrlParameter && location?.search) {
      const queryStrings = queryString.parse(location.search)
      const newValue = queryStrings[urlKey]
      if (newValue !== undefined && value !== newValue) {
        if (setActiveTab) {
          setActiveTab(newValue as ITabKey)
        }
        setValue(newValue as ITabKey)
      }
    }
    return {}
  }

  useLocationChange(routeToTab)

  return (
    <>
      <AppBar>
        <Tabs
          value={value}
          variant={scrollable ? 'scrollable' : 'standard'}
          color="default"
          onChange={handleChange}
        >
          {startAddon}
          {tabs?.map((tab, i) => (
            <Tab key={tab.key} value={tab.key || i} label={tab.label} {...a11yProps(i)} />
          ))}
          {onAdd && (
            <Button color="primary" startIcon={<OutlinedAdd />} onClick={onAdd}>
              {addButtonLabel}
            </Button>
          )}

          {extra && (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>{extra}</div>
          )}
        </Tabs>
      </AppBar>
      {tabs?.map((tab, i) => (
        <TabPanel key={tab.key || i} value={value} index={tab.key || i}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  )
}

export default React.memo(TabbedContent)
