import React, { useState, useEffect, useCallback } from 'react'
import { List } from '@mui/material'
import { MenuItem } from '../../atoms'
import { IMenuItem } from '../../atoms/MenuItem'

interface IMenuListItem extends IMenuItem {
  permissionId?: string
}

interface IMenuList {
  menuItems?: IMenuListItem[]
  defaultActive?: number
  open?: boolean
  onChange?: (active?: number) => void
  disabled?: string[]
}

const MenuList: React.FC<IMenuList> = ({
  menuItems = [],
  defaultActive = undefined,
  open = true,
  onChange = () => {},
  disabled = undefined
}) => {
  const [active, setActive] = useState<number | undefined>(undefined)
  const handleActiveMountState = useCallback(() => {
    setActive(defaultActive)
  }, [defaultActive])
  useEffect(() => {
    handleActiveMountState()
  }, [handleActiveMountState])
  const handleChange = useCallback(() => {
    onChange?.(active)
  }, [active, onChange])
  useEffect(() => {
    handleChange()
  }, [handleChange])
  return (
    <List>
      {menuItems
        ?.filter(item => !disabled?.includes(item.permissionId || ''))
        ?.map((item, index) => {
          const {
            icon,
            suffixIcon,
            label,
            isNavItem,
            to,
            externalLink,
            additionalHref = undefined
          } = item
          return (
            <div
              key={item.permissionId || item.label}
              style={{ ...(index < menuItems.length - 1 && { paddingBottom: '20px' }) }}
            >
              <MenuItem
                icon={icon}
                suffixIcon={suffixIcon}
                label={label}
                active={active === index}
                onSelect={() => setActive(index)}
                collapse={!open}
                isNavItem={isNavItem}
                to={to}
                additionalHref={additionalHref}
                externalLink={externalLink}
              />
            </div>
          )
        })}
    </List>
  )
}

export default MenuList
