import { useContext, useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router'
import TitleContext from '../layouts/TitleContext'
import CaseContext from './Context/CaseContext'
import EditorContext from './Context/EditorContext'
import ProfilContext from './Context/ProfileContext'
import { getUid, getToken, getClient } from './helper'
import UserContext from './UserContext'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

interface IUseFetchExportParams {
  headers: HeadersInit
}

interface DataLoadingErrorObject {
  data?: Blob | null
  error?: any
  loading: boolean
}

type IUseFetchExport = [
  DataLoadingErrorObject,
  React.Dispatch<React.SetStateAction<string | undefined>>
]

export const useFetchExport: (headers: IUseFetchExportParams) => IUseFetchExport = ({
  headers
}) => {
  const [data, setData] = useState<Blob | null>(null)
  const [error, setError] = useState<any>(false)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState<string | undefined>(undefined)
  useEffect(() => {
    const fetchData = async () => {
      setError(false)
      setLoading(true)
      if (url) {
        try {
          let filename: string
          const result = await fetch(url, {
            method: 'GET',
            headers: {
              ...headers,
              'access-token': getToken() as string,
              uid: getUid() as string,
              client: getClient() as string
            }
          })
            .then(res => {
              // eslint-disable-next-line prefer-destructuring
              filename = res.headers.get('content-disposition')?.split('"')[1] || ''
              return res.blob()
            })
            .then(blob => {
              const downloadUrl = window.URL.createObjectURL(new Blob([blob]))
              const link = document.createElement('a')
              link.href = downloadUrl
              link.setAttribute('download', filename)
              link.click()
              link.parentNode?.removeChild(link)
              return blob
            })
            .finally(() => setUrl(undefined))
          setData(result)
        } catch (err) {
          setError(err)
        }
      }
      setLoading(false)
    }

    if (url) fetchData()
  }, [url, headers])
  return [{ data, loading, error }, setUrl]
}

export const useFetchAWS: (headers: IUseFetchExportParams) => IUseFetchExport = ({ headers }) => {
  const [data, setData] = useState<Blob | null>(null)
  const [error, setError] = useState<any>(false)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState<string | undefined>(undefined)
  useEffect(() => {
    const fetchData = async () => {
      setError(false)
      setLoading(true)
      if (url) {
        try {
          const result = await fetch(url, {
            method: 'GET',
            headers: {
              ...headers,
              'access-token': getToken() as string,
              uid: getUid() as string,
              client: getClient() as string
            }
          })
            .then(res => res.json())
            .then(res => {
              const link = document.createElement('a')
              link.setAttribute('target', '_blank')
              link.href = res.url
              link.click()
              link.parentNode?.removeChild(link)
              return res
            })
            .finally(() => setUrl(undefined))
          setData(result)
        } catch (err) {
          setError(err)
        }
      }
      setLoading(false)
    }

    if (url) fetchData()
  }, [url, headers])
  return [{ data, loading, error }, setUrl]
}

export const useLocalStorageJSON = (key: string, initialState?: any) => {
  const serializedInitialState = JSON.stringify(initialState)
  let storageValue = initialState
  try {
    const item = localStorage.getItem(key)
    storageValue = typeof item === 'string' ? JSON.parse(item) : initialState
  } catch {
    localStorage.setItem(key, serializedInitialState)
  }
  const [value, setValue] = useState(storageValue)
  const updatedSetValue = useCallback(
    (newValue: any) => {
      const serializedNewValue = JSON.stringify(newValue)
      if (serializedNewValue === serializedInitialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, serializedNewValue)
      }
      setValue(newValue ?? initialState)
    },
    [initialState, serializedInitialState, key]
  )
  return [value, updatedSetValue]
}

export const useLocalStorage = (key: string, initialState?: any) => {
  const [value, setValue] = useState(localStorage.getItem(key) ?? initialState)
  const updatedSetValue = useCallback(
    (newValue: any) => {
      if (newValue === initialState || typeof newValue === 'undefined') {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, newValue)
      }
      setValue(newValue ?? initialState)
    },
    [initialState, key]
  )
  return [value, updatedSetValue]
}

export const useLocationChange = (action: (location: any) => {}) => {
  const location = useLocation()
  useEffect(() => {
    action(location)
  }, [action, location])
}

export const useCase = () => {
  const caseContextValues = useContext(CaseContext)
  return caseContextValues
}

export const useTitle = () => {
  const titleContextValues = useContext(TitleContext)
  return titleContextValues
}

export const useEditor = () => {
  const editorContextValues = useContext(EditorContext)
  return editorContextValues
}

export const useProfile = () => {
  const profileContextValues = useContext(ProfilContext)
  return profileContextValues
}

export const useUser = () => {
  const userContextValues = useContext(UserContext)
  return userContextValues
}
