import React, { Suspense } from 'react'
import ContextWrapper from './ContextWrapper'
import { TitleContextProvider } from './TitleContext'

interface MainLayoutProps {
  children: React.ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => (
  <Suspense fallback={null}>
    <TitleContextProvider>
      <ContextWrapper>{children}</ContextWrapper>
    </TitleContextProvider>
  </Suspense>
)

export default MainLayout
