import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import CheckableFormItem from '../CheckableFormItem'
import FormItem from '../FormItem'

interface Props {
  checkable?: boolean
  elements: any
  sx?: {}
}

const InputGroup: React.FC<Props> = React.memo(({ checkable, elements, sx }) => (
  <Grid container spacing={2} sx={sx}>
    {checkable ? (
      <>
        {
          // @ts-ignore
          elements.map(({ sm, md, lg, xl, xs, ...elementProps }) => (
            <Grid item key={elementProps.name} xs={xs || 12} sm={sm} md={md} lg={lg} xl={xl}>
              <CheckableFormItem {...elementProps} />
            </Grid>
          ))
        }
      </>
    ) : (
      <>
        {
          // @ts-ignore
          elements.map(({ sm, md, lg, xl, xs, ...elementProps }) => (
            <Grid
              sx={elementProps.sx}
              item
              key={elementProps.name}
              xs={xs || 12}
              sm={sm}
              md={md}
              lg={lg}
              xl={xl}
            >
              {/* @ts-ignore */}
              <FormItem {...elementProps} />
            </Grid>
          ))
        }
      </>
    )}
  </Grid>
))

InputGroup.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      label: PropTypes.string,
      query: PropTypes.any,
      queryOptions: PropTypes.object,
      disabled: PropTypes.bool
    })
  ).isRequired,
  checkable: PropTypes.bool,
  sx: PropTypes.object
}
InputGroup.defaultProps = {
  checkable: false,
  sx: {}
}

export default InputGroup
