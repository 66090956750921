import React, { useCallback, useEffect, useState } from 'react'
// eslint-disable-next-line
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import PropTypes from 'prop-types'
import { isString } from 'lodash'
import 'react-phone-number-input/style.css'
import de from 'react-phone-number-input/locale/de.json'

const phoneNumberInputWithoutLeadingZero = (inputValue: string) => {
  if (!isString(inputValue)) return inputValue
  const transformedInputNumber = inputValue.trim().replace('+0', '+49')

  const phoneNumber = parsePhoneNumber(transformedInputNumber)

  if (phoneNumber)
    return `+${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber.replace(/^0+/, '')}`

  return inputValue
}

interface PhoneNumberInputProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  name?: string
  value?: string
}

const PhoneNumberInput = React.forwardRef<any, PhoneNumberInputProps>(
  ({ onChange: onChangeValue, name = '', value: propValue, ...other }, ref) => {
    const [value, setValue] = useState<string | undefined>(propValue)
    useEffect(() => {
      setValue(phoneNumberInputWithoutLeadingZero(propValue as string))
    }, [setValue, propValue])
    const handleChange = useCallback(
      (newValue: string = '') => {
        const changedValue = phoneNumberInputWithoutLeadingZero(newValue as string)
        setValue(changedValue)
        onChangeValue?.({
          target: {
            name,
            value: changedValue
          }
        } as React.ChangeEvent<HTMLInputElement>)
      },
      [setValue, onChangeValue, name]
    )
    return (
      <PhoneInput
        {...other}
        value={value}
        flagUrl="/assets/flags/{xx}.svg"
        ref={ref}
        onChange={handleChange}
        defaultCountry="DE"
        labels={de}
        international
      />
    )
  }
)

PhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default PhoneNumberInput
