import React from 'react'
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Avatar, IconButton } from '../../atoms'

const Row = styled(Box)(() => ({
  padding: '20px',
  display: 'flex',
  alignItems: 'center'
}))

interface UserProps {
  name: string
  imageSrc?: string
  role: string
}

interface UserCardProps {
  user?: UserProps
  open?: boolean
  logout?: () => void
  onClick?: () => void
}

const UserCard: React.FC<UserCardProps> = ({
  user = { name: 'Anonymous', role: 'Admin' },
  open = true,
  logout,
  onClick
}) =>
  open ? (
    <Row>
      <Avatar onClick={onClick} src={user.imageSrc || undefined} alt={user.name || ''} />
      <Box ml={2}>
        <Typography
          variant="h6"
          sx={{
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'common.white'
          }}
        >
          {user?.name}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: 'grey.200' }}>
          {user?.role}
        </Typography>
      </Box>
      {logout && (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <IconButton icon={<PowerSettingsNewSharpIcon />} onClick={logout} size="large" />
        </Box>
      )}
    </Row>
  ) : (
    <Row>
      <Avatar onClick={onClick} src={user?.imageSrc || undefined} alt={user?.name || ''} small />
    </Row>
  )

export default UserCard
