import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

export interface CustomNumberProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  name?: string
}

const CustomNumber = React.forwardRef((props: CustomNumberProps, ref) => {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange?.({
          target: {
            name: props.name,
            value: values.value
          }
        } as React.ChangeEvent<HTMLInputElement>)
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      isNumericString
      fixedDecimalScale
    />
  )
})

CustomNumber.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default CustomNumber
