import React from 'react'
import Text from '../Text'

interface SubtitleProps {
  children?: React.ReactNode
  sx?: object
}

const Subtitle: React.FC<SubtitleProps> = ({ children, sx }) => (
  <Text variant="subtitle" component="h2" sx={sx}>
    {children}
  </Text>
)

Subtitle.defaultProps = {
  children: null,
  sx: {}
}

export default Subtitle
