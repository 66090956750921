import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Button } from '../../atoms'

export interface ConfirmationDialogProps {
  open: boolean
  title?: string
  content?: string | React.ReactNode
  handleCancel?: () => void
  handleConfirm?: () => void
  cancelButtonText?: string
  confirmButtonText?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  fullWidth?: boolean
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title = '',
  content = '',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  handleCancel = () => {},
  handleConfirm = () => {},
  maxWidth = 'sm',
  fullWidth = true
}) => (
  <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} style={{ font: 'inherit' }}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancel} variant="contained" color="secondary" autoFocus>
        {cancelButtonText}
      </Button>
      <Button onClick={handleConfirm} variant="contained" color="primary" autoFocus>
        {confirmButtonText}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmationDialog
