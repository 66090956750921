import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RemoveCircleOutline } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'
import { Button } from '../../atoms'
import PopOverConfirmDialog from './PopOverConfirmDialog'

export interface IConfirmDeleteDialog {
  onConfirm: () => void
  message?: string
}
const ConfirmDeleteDialog: FC<IConfirmDeleteDialog> = ({ onConfirm, message }) => {
  const { t } = useTranslation('common')
  const confirmButtonSx = (theme: Theme) => ({
    color: theme.palette.error.main,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.main
    }
  })

  const abortButtonSx = (theme: Theme) => ({
    backgroundColor: theme.palette.primary.main
  })

  const deleteButtonSx = (theme: Theme) => ({
    color: theme.palette.error.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.error.main
    }
  })
  return (
    <PopOverConfirmDialog
      onConfirm={onConfirm}
      confirmButtonSx={confirmButtonSx}
      abortButtonSx={abortButtonSx}
      message={message}
    >
      <Button color="secondary" sx={deleteButtonSx} startIcon={<RemoveCircleOutline />}>
        {t('common:delete')}
      </Button>
    </PopOverConfirmDialog>
  )
}

export default ConfirmDeleteDialog
