import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { uniqueId } from 'lodash'
import { OutlinedPlus } from '../../../assets/icons'
import OverflowTip from '../OverflowTip'

export interface IMenuItem {
  icon: React.ReactNode
  suffixIcon?: React.ReactNode
  label: string
  isNavItem?: boolean
  to?: string
  additionalHref?: string
  collapse?: boolean
  active?: boolean
  externalLink?: boolean
  onSelect?: () => void
}

const getLink = (to: string, externalLink: boolean, isNavLink: boolean) => {
  const returnValues = {
    component: undefined,
    href: undefined,
    to: undefined,
    target: undefined
  } as any
  if (isNavLink) {
    returnValues.component = NavLink
    returnValues.to = to
  } else if (externalLink) {
    returnValues.component = 'a'
    returnValues.href = to
    returnValues.target = '_blank'
  } else {
    returnValues.component = 'span'
  }
  return returnValues
}

const MenuItem: React.FC<IMenuItem> = ({
  additionalHref = undefined,
  icon = <div>a</div>,
  suffixIcon,
  label = '',
  collapse = false,
  active = false,
  isNavItem = false,
  to = '',
  externalLink = false,
  onSelect = () => {}
}) => {
  const [isActive, setIsActive] = useState(active)
  const location = useLocation()
  useEffect(() => {
    const { pathname } = location
    if (pathname && isNavItem) {
      const sp = new URLSearchParams(location.search)

      setIsActive(pathname === (to || '').replace(`?${sp.toString()}`, ''))
    }
  }, [isNavItem, location, to])

  interface IconWithStyle extends React.HTMLAttributes<HTMLElement> {
    style?: React.CSSProperties
  }

  const iconWithProps = React.isValidElement(icon)
    ? React.cloneElement(icon, { style: { height: '20px', width: '20px' } } as IconWithStyle)
    : icon
  const suffixIconWithProps = React.isValidElement(suffixIcon)
    ? React.cloneElement(suffixIcon, { style: { height: '20px', width: '20px' } } as IconWithStyle)
    : suffixIcon
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <ListItemButton
        {...getLink(to || '', externalLink, isNavItem)}
        key={uniqueId()}
        sx={{
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: collapse ? '20px' : '37px',
          minHeight: '20px',
          fontWeight: 400
        }}
        onClick={!isNavItem ? onSelect : undefined}
      >
        <OverflowTip title={label} position="right" sx={{ display: 'flex', p: 0, m: 0 }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              paddingRight: '12px',
              color: isActive ? 'common.white' : 'grey.200'
            }}
          >
            {iconWithProps}
          </ListItemIcon>
        </OverflowTip>
        <ListItemText
          primary={label}
          disableTypography
          sx={{
            display: collapse ? 'none' : '',
            color: isActive ? 'common.white' : 'grey.200',
            fontWeight: isActive ? 'typography.fontWeightBold' : 'typography.fontWeightRegular',
            margin: 0,
            lineHeight: '20px',
            fontSize: '1.5rem'
          }}
        />
        {suffixIcon && (
          <ListItemIcon
            sx={{
              display: collapse ? 'none' : '',
              minWidth: 0,
              paddingRight: '28px',
              color: isActive ? 'common.white' : 'grey.200'
            }}
          >
            {suffixIconWithProps}
          </ListItemIcon>
        )}
      </ListItemButton>
      {additionalHref && (
        <ListItemButton
          component={NavLink}
          to={additionalHref}
          sx={{
            display: collapse ? 'none' : '',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: '28px',
            width: 'fit-content'
          }}
        >
          <ListItemIcon sx={{ minWidth: 0, paddingRight: '12px', color: 'grey.200' }}>
            <OutlinedPlus style={{ width: '20px', height: '20px' }} />
          </ListItemIcon>
        </ListItemButton>
      )}
    </Box>
  )
}

export default MenuItem
