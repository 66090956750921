import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Select } from '../../atoms'

const CaseStateSelect = ({ control, hidden, disabled }) => {
  const { t } = useTranslation('caseProperty')
  const items = [
    { value: 'in_capture', label: t('inCapture') },
    {
      value: 'open',
      label: t('open')
    },
    {
      value: 'coverage_request',
      label: t('coverageRequest')
    },
    {
      value: 'in_progress',
      label: t('inProgress')
    },
    {
      value: 'jump_off',
      label: t('jumpOff')
    },
    {
      value: 'completed',
      label: t('completed')
    },
    {
      value: 'checked',
      label: t('checked')
    },
    {
      value: 'settled',
      label: t('settled')
    }
  ]
  if (hidden) return null
  return (
    <Controller
      control={control}
      name="caseState"
      render={({ field: { onChange, value, ref } }) => (
        <Select
          variant="standard"
          value={value}
          onChange={onChange}
          ref={ref}
          control={control}
          items={items}
          label={t('caseState')}
          disabled={disabled}
        />
      )}
    />
  )
}
CaseStateSelect.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool
}

CaseStateSelect.defaultProps = {
  disabled: false,
  hidden: false
}

export default CaseStateSelect
