import React from 'react'
import { Box } from '@mui/material'

export interface TabPanelProps {
  children: React.ReactNode
  index: any
  value: any
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...other}
  >
    {value === index && <Box pt={4}>{children}</Box>}
  </div>
)

export default TabPanel
