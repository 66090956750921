import moment from 'moment'
import restrictions, { RestrictionKey, Role, VisibilityKey } from './restrictions'

export const TOKEN_KEY = 'm2.access-token'
export const setToken = (token: string) => localStorage.setItem(TOKEN_KEY, token)
export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const removeToken = () => localStorage.removeItem(TOKEN_KEY)

export const UID_KEY = 'm2.uid'
export const setUid = (uid: string) => localStorage.setItem(UID_KEY, uid)
export const getUid = () => localStorage.getItem(UID_KEY)
export const removeUid = () => localStorage.removeItem(UID_KEY)

export const CLIENT_KEY = 'm2.client'
export const setClient = (client: string) => localStorage.setItem(CLIENT_KEY, client)
export const getClient = () => localStorage.getItem(CLIENT_KEY)
export const removeClient = () => localStorage.removeItem(CLIENT_KEY)

export const EXPIRY_KEY = 'm2.expiry'
export const setExpiry = (expiry: string) => localStorage.setItem(EXPIRY_KEY, expiry)
export const getExpiry = () => localStorage.getItem(EXPIRY_KEY)
export const removeExpiry = () => localStorage.removeItem(EXPIRY_KEY)

export const USER_ID_KEY = 'm2.user.id'
export const getUserId = () => localStorage.getItem(USER_ID_KEY)
export const setUserId = (id: string) => localStorage.setItem(USER_ID_KEY, id)
export const removeUserId = () => localStorage.removeItem(USER_ID_KEY)

export const USER_KEY = 'm2.user'
export const setUser = (user: any) => localStorage.setItem(USER_KEY, JSON.stringify(user))
export const getUser = () => localStorage.getItem(USER_KEY)
export const removeUser = () => localStorage.removeItem(USER_KEY)

export const SIDEBAR_KEY = 'm2.sidebar'
export const setSidebarOpen = (open: boolean) =>
  localStorage.setItem(SIDEBAR_KEY, JSON.stringify(open))

// NOTE: null throws no error, empty string does
// @ts-ignore
export const getSidebarOpen = () => JSON.parse(localStorage.getItem(SIDEBAR_KEY))

export const CASES_COLUMNS_KEY = 'm2.cases.columns'

// NOTE: null throws no error, empty string does
// @ts-ignore
export const getStoredCasesColumns = () => JSON.parse(localStorage.getItem(CASES_COLUMNS_KEY))

export const TIME_KEY = 'm2.logout.time'
export const setTimer = (time: string) => localStorage.setItem(TIME_KEY, time)
export const getTimer = () => localStorage.getItem(TIME_KEY)
export const removeTimer = () => localStorage.removeItem(TIME_KEY)

export const CASES_FILTER_KEY = 'm2.cases.filter'
export const CASES_SORT_KEY = 'm2.cases.sort'
export const CASES_TEXTSEARCH_KEY = 'm2.cases.textSearch'
export const CASES_LIMIT_KEY = 'm2.cases.limit'

const storeCasesColumns = (columns: any[]) =>
  localStorage.setItem(CASES_COLUMNS_KEY, JSON.stringify(columns))

export const setCasesColumns = (columns: any[]) => {
  const colsToStore = columns.map((col, index) => ({
    key: col.key,
    position: index + 1
  }))
  storeCasesColumns(colsToStore)
}

export const COLUMN_SELECTION_TITLE_KEY = 'm2.cases.selection.title'
export const getCurrentSelectionTitleFromStorage = () =>
  localStorage.getItem(COLUMN_SELECTION_TITLE_KEY)
export const setCurrentSelectionTitleToStorage = (sectionTitle: string) =>
  sectionTitle
    ? localStorage.setItem(COLUMN_SELECTION_TITLE_KEY, sectionTitle)
    : localStorage.removeItem(COLUMN_SELECTION_TITLE_KEY)

export const isLogin = () => !!getToken()

export const getDateFromString = (date: string | Date | undefined | null) => {
  if (!date || !moment(date).isValid()) {
    return null
  }

  return moment(date).toDate()
}

export const moveElementInArray = (arr: any[], from: number, to: number) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
  return arr
}

export const clearUserData = () => {
  removeToken()
  removeUid()
  removeClient()
  removeExpiry()
  removeUserId()
  removeUser()
  localStorage.removeItem(CASES_FILTER_KEY)
}

export const getNumberFromString = (value: string) =>
  !Number.isNaN(parseFloat(value)) ? Number(value) : null

export const getIntFromString = (value: string) => {
  // NOTE: ruby's max int value is 2147483647
  const MAX_INT_VALUE = 2147483647
  const number = !Number.isNaN(parseInt(value)) ? parseInt(value) : null
  if (number && number > MAX_INT_VALUE) return MAX_INT_VALUE
  return number
}

export const getNewFieldValue = (
  dirty: any,
  values: any,
  name: string,
  index?: number,
  arrayName?: string
) => {
  if (arrayName && index !== undefined && Number.isInteger(index)) {
    return dirty?.[arrayName]?.[index]?.[name]
      ? getNumberFromString(values?.[arrayName]?.[index]?.[name])
      : undefined
  }
  return dirty[name] ? getNumberFromString(values?.[name]) : undefined
}
export const getNewStringFieldValue = (
  dirty: any,
  values: any,
  name: string,
  index?: number,
  arrayName?: string
) => {
  if (arrayName && index !== undefined && Number.isInteger(index)) {
    const arrayDirty = dirty?.[arrayName]?.[index]?.[name]
    const arrayValue =
      typeof values?.[arrayName]?.[index]?.[name] === 'string'
        ? values[arrayName][index][name].trim()
        : null

    return arrayDirty ? arrayValue : undefined
  }
  const dirtyValue = dirty[name]
  const value = typeof values[name] === 'string' ? values[name].trim() : null
  return dirtyValue ? value : undefined
}
export const getInsuranceDependentFieldValueFromArray = (
  dirty: any,
  values: any,
  name: string,
  index: number,
  arrayName: string
) => {
  if (dirty.insuranceId && dirty?.[arrayName]?.[index]?.[name]) {
    return values?.[arrayName]?.[index]?.[name]
  }
  if (dirty.insuranceId && !dirty?.[arrayName]?.[index]?.[name]) {
    return null
  }
  return values?.[arrayName]?.[index]?.[name]
}

export const getInsuranceDependentFieldValue = (dirty: any, values: any, name: string) => {
  if (dirty.insuranceId && dirty?.[name]) {
    return values?.[name]
  }

  if (dirty.insuranceId && !dirty?.name) {
    return null
  }
  return values?.[name]
}

export const getProjectDependentFieldValue = (dirty: any, values: any, name: string) => {
  if (dirty.projectId && dirty?.[name]) {
    return values?.[name]
  }

  if (dirty.projectId && !dirty?.name) {
    return null
  }
  return values?.[name]
}

export const getProjectDependentFieldValueFromArray = (
  dirty: any,
  values: any,
  name: string,
  index: number,
  arrayName: string
) => {
  if (dirty.projectId && dirty?.[arrayName]?.[index]?.[name]) {
    return values?.[arrayName]?.[index]?.[name]
  }
  if (dirty.projectId && !dirty?.[arrayName]?.[index]?.[name]) {
    return null
  }
  return values?.[arrayName]?.[index]?.[name]
}

export const getProcessingsAttributesValue = (processingsAttributes: any, dirty: any, vals: any) =>
  processingsAttributes.map((pa: any, i: number) => ({
    ...pa,
    deletable: undefined,
    agent: undefined,
    assignedFrom: undefined,
    involvedUsers: undefined,
    m2Case: undefined,
    lastActionAt: undefined,
    nextTaskAt: undefined,
    nextTaskComment: undefined,
    firstCallDelayed: undefined,
    costFromActualProcessingType: undefined,
    cost: getNewFieldValue(dirty, vals, 'cost', i, 'processingsAttributes'),
    additionalCost: getNewFieldValue(dirty, vals, 'additionalCost', i, 'processingsAttributes'),
    income: getNewFieldValue(dirty, vals, 'income', i, 'processingsAttributes'),
    additionalIncome: vals.processingsAttributes[i]?.additionalIncome
      ? getNewFieldValue(dirty, vals, 'additionalIncome', i, 'processingsAttributes')
      : 0,
    receivedPayment: getNewFieldValue(dirty, vals, 'receivedPayment', i, 'processingsAttributes'),
    intendedProcessingType: undefined,
    intendedProcessingTypeId: getProjectDependentFieldValueFromArray(
      dirty,
      vals,
      'intendedProcessingTypeId',
      i,
      'processingsAttributes'
    ),
    invoice: undefined,
    actualProcessingType: undefined,
    actualProcessingTypeId: getProjectDependentFieldValueFromArray(
      dirty,
      vals,
      'actualProcessingTypeId',
      i,
      'processingsAttributes'
    ),
    assignedProcessingType: undefined,
    assignedProcessingTypeId: getProjectDependentFieldValueFromArray(
      dirty,
      vals,
      'assignedProcessingTypeId',
      i,
      'processingsAttributes'
    ),
    settlementTypeId: getProjectDependentFieldValueFromArray(
      dirty,
      vals,
      'settlementTypeId',
      i,
      'processingsAttributes'
    ),
    coverage: getNewStringFieldValue(dirty, vals, 'coverage', i, 'processingsAttributes'),
    coverageComments: getNewStringFieldValue(
      dirty,
      vals,
      'coverageComments',
      i,
      'processingsAttributes'
    )
  }))

export const getDisabledFields = (fields: any, userRole: Role, setName: RestrictionKey) => {
  const disable = restrictions()
    ?.[setName]?.filter(item => item.roles.includes(userRole))
    ?.map(item => item.name)

  return fields?.map((field: any) =>
    disable.includes(field.permissionId) ? { ...field, disabled: true } : { ...field }
  )
}
export const getDisabledMenuItems = (role: Role) =>
  restrictions()
    .menuItems.filter(item => item.roles?.includes(role))
    ?.map(item => item.name)

export const getDefaultEntryPoint = () => restrictions().defaultEntry

export const getUserEntryPoint = (role: Role) =>
  restrictions()
    .entries.filter(item => item.roles?.includes(role))
    ?.map(item => item.name)[0] || getDefaultEntryPoint()

export const getDisabledRoutes = (role: Role) =>
  restrictions()
    .routes.filter(route => route.roles?.includes(role))
    ?.map(route => route.name)

export const getDisabledElement = (role: Role, level: VisibilityKey) => {
  const found = restrictions()?.[level]?.find(item => item?.role === role)?.visibility
  return { hidden: found === 'hidden', disabled: found === 'disabled' }
}

export const resolveNestedObject = (path: string, obj: any) =>
  path?.split('.')?.reduce((prev, curr) => (prev ? prev[curr] : null), obj)

export const getAddress = (customer: any) => {
  const street = customer?.street ? `${customer?.street} ${customer?.houseNumber}` : undefined
  const postal =
    customer?.postalCode?.length || customer?.place?.length
      ? [customer?.postalCode, customer?.place].join(' ')
      : undefined

  return street || postal ? [street, postal].join(', ') : '-'
}

export const arrayUniqueByKey: (array: any[], key: string) => any[] = (array, key) => {
  const map = new Map(array.map(item => [item[key], item]))
  return Array.from(map.values())
}

export const getEmptyContactPerson = () => ({
  salutation: undefined,
  firstname: undefined,
  lastname: undefined,
  email: undefined,
  relationToPolicyholder: undefined,
  phoneNumber: undefined,
  mobileNumber: undefined
})

export const getEmptyCustomer = () => ({
  salutation: undefined,
  lastname: undefined,
  firstname: undefined,
  birthDate: undefined,
  company: undefined,
  street: undefined,
  houseNumber: undefined,
  postalCode: undefined,
  place: undefined,
  phoneNumber: undefined,
  mobileNumber: undefined,
  email: undefined,
  comments: undefined,
  firstSignInAt: undefined
})

export const createRegExpWithFlags = (pattern: string) => {
  // Regular expression to match flag patterns like (?i), (?g), etc.
  const flagPattern = /\(\?[a-z]\)/g

  // Extract flags from the pattern
  let flags = ''
  let match = flagPattern.exec(pattern)
  while (match !== null) {
    // Extract the flag character and append it to the flags string
    flags += match[0][2]
    match = flagPattern.exec(pattern)
  }

  // Remove the flag patterns from the original pattern
  const cleanedPattern = pattern.replace(flagPattern, '')

  // Create the RegExp object with the cleaned pattern and extracted flags
  const regex = new RegExp(cleanedPattern, flags)

  return regex
}
