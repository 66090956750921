import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

const isDev = process.env.REACT_APP_ENV === 'development'

const ErrorView = ({ error }) => (
  <div
    style={{
      paddingTop: '50px',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1080,
      margin: 'auto'
    }}
  >
    <Typography variant="H2">Ein Fehler ist aufgetreten</Typography>
    <div style={{ backgroundColor: 'lightgray' }}>{isDev && error.message}</div>
  </div>
)
ErrorView.propTypes = {
  error: PropTypes.object.isRequired
}

export default ErrorView
