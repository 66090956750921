import React, { useEffect, useState, cloneElement, isValidElement, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Box, Drawer as MuiDrawer } from '@mui/material'
import { Cross } from '../../../assets/icons'
import IconButton from '../IconButton'

const heightBottomBar = 100

const Drawer = ({
  toggleButton,
  children,
  drawerWidth,
  withBottomBar,
  bottomBarComponent,
  open: propOpen,
  setOpen: setPropOpen,
  variant
}) => {
  const [open, setOpen] = useState(propOpen)
  useEffect(() => {
    setOpen(propOpen)
  }, [setOpen, propOpen])

  const onClick = () => {
    setPropOpen?.(!open)
    setOpen(!open)
  }

  const onClose = () => {
    setOpen(false)
    setPropOpen?.(false)
  }

  return (
    <Suspense fallback={null}>
      {isValidElement(toggleButton) &&
        (open !== undefined ? cloneElement(toggleButton) : cloneElement(toggleButton, { onClick }))}
      <MuiDrawer variant={variant} anchor="right" open={open} onClose={onClose}>
        <Box sx={{ position: 'absolute', right: '28px', top: '28px' }}>
          <IconButton color="secondary" icon={<Cross />} onClick={onClose} size="large" />
        </Box>
        <Box
          sx={{
            p: 6,
            height: withBottomBar ? `calc(100% - ${heightBottomBar}px)` : '100%',
            overflowY: 'auto',
            maxWidth: drawerWidth
          }}
        >
          {children}
        </Box>
        {withBottomBar && bottomBarComponent && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              backgroundColor: 'common.white',
              bottom: 0,
              width: `${drawerWidth}`,
              height: `${heightBottomBar}px`,
              px: 6,
              boxShadow: '0 -2px 16px 0 rgba(75, 85, 99, 0.13)'
            }}
          >
            {bottomBarComponent}
          </Box>
        )}
      </MuiDrawer>
    </Suspense>
  )
}

Drawer.propTypes = {
  toggleButton: PropTypes.node,
  children: PropTypes.node.isRequired,
  drawerWidth: PropTypes.number,
  withBottomBar: PropTypes.bool,
  open: PropTypes.bool,
  bottomBarComponent: PropTypes.node,
  setOpen: PropTypes.func,
  variant: PropTypes.oneOf(['temporary', 'permanent', 'persistent'])
}
Drawer.defaultProps = {
  toggleButton: <button type="button">open drawer</button>,
  variant: undefined,
  drawerWidth: 800,
  withBottomBar: false,
  open: false,
  bottomBarComponent: null,
  setOpen: () => {}
}

export default Drawer
