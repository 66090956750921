import React from 'react'
import { FormHelperText, Grid, Rating as MRating, SvgIcon } from '@mui/material'
import { ReactComponent as RatingEmptyIcon } from '../../../assets/icons/rating-empty.svg'
import { ReactComponent as RatingIcon } from '../../../assets/icons/rating.svg'

const StyledRating = React.forwardRef((props: any, ref: React.Ref<any>) => (
  <MRating
    ref={ref}
    {...props}
    sx={(theme: any) => ({
      '& .MuiRating-iconFilled': {
        color: theme.palette.petrol
      },
      '& .MuiRating-iconEmpty': {
        color: theme.palette.grey[400]
      }
    })}
  />
))
interface RatingProps {
  max?: number
  value?: number
  precision?: number
  name?: string
  onChange(event: React.SyntheticEvent<Element, Event>, value: number | null): void
  readOnly?: boolean
  disabled?: boolean
  helperText?: string
  error?: any
  id?: string
}

const Rating = React.forwardRef<React.ReactElement, RatingProps>(
  (
    { max = 5, name, onChange, precision = 1, readOnly, value, disabled, helperText, error, id },
    ref
  ) => (
    <Grid container>
      <Grid xs={12}>
        <StyledRating
          id={id}
          ref={ref}
          max={max}
          name={name}
          onChange={onChange}
          precision={precision}
          readOnly={readOnly}
          value={value}
          icon={<SvgIcon component={RatingIcon} />}
          emptyIcon={<SvgIcon component={RatingEmptyIcon} />}
          disabled={disabled}
        />
      </Grid>
      <Grid xs={12}>
        <FormHelperText error={!!error}>{helperText}</FormHelperText>
      </Grid>
    </Grid>
  )
)

export default Rating
