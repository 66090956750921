import React, { useState } from 'react'

const initialValues: TitleValues = {
  title: '',
  subTitle: '',
  extra: null
}
const context = {
  values: initialValues,
  setValues: () => undefined
}

interface TitleContextType {
  values: TitleValues
  setValues: React.SetStateAction<TitleValues>
}

const TitleContext = React.createContext<TitleContextType | any>(context)

interface TitleValues {
  title: string | null | React.ReactElement
  subTitle?: string | null | React.ReactElement
  extra?: string | null | React.ReactElement
}

interface TitleContextProps {
  children: JSX.Element
}

export const TitleContextProvider: React.FC<TitleContextProps> = ({ children }) => {
  const [values, setValues] = useState<TitleValues>(initialValues)
  return <TitleContext.Provider value={{ values, setValues }}>{children}</TitleContext.Provider>
}

export default TitleContext
