import React from 'react'
import { Tab as MTab, TabProps } from '@mui/material'

const Tab = (props: TabProps) => (
  <MTab
    sx={(theme: any) => ({
      padding: '0 0',
      minWidth: '0',
      minHeight: '0',
      textTransform: 'none',
      color: theme?.palette?.grey?.[500],
      fontWeight: theme?.typography?.fontWeightMedium,
      fontSize: '1.25rem',
      marginRight: '29px',
      '& .MuiTabs-scroller .MuiTabs-flexContainer .': { border: '2px solid blue' },
      '&:focus': {
        opacity: 1
      }
    })}
    {...props}
  />
)
export default Tab
