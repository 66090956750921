import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { ACTIONS_QUERY, USER_STATS_QUERY } from '../../graphql/queries'
import { Action, UserStat } from '../fixtures'
import UserContext from '../UserContext'

const initialValues = {
  userStats: undefined,
  actions: [],
  loadingActions: true,
  loadingUserStats: true,
  errorActions: undefined,
  errorUserStats: undefined,
  refetchUserStats: () => {},
  refetchActions: () => {},
  processingId: undefined,
  isEditorModalOpen: false
}

type ContextType = {
  processingId?: string
  setProcessingId: Dispatch<SetStateAction<string | undefined>>
  actions?: Action[]
  userStats?: UserStat
  loadingActions: boolean
  loadingUserStats: boolean
  errorActions?: ApolloError
  errorUserStats?: ApolloError
  isEditorModalOpen: boolean
  setIsEditorModalOpen: Dispatch<SetStateAction<boolean>>
  refetchUserStats: (variables?: undefined) => Promise<ApolloQueryResult<any>>
  refetchActions: (
    variables?:
      | Partial<{
          processingId?: string
          sortBy?: { attribute: string; direction: string }
          filter?: { [key: string]: any }
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>
}
const context = {
  processingId: initialValues.processingId,
  setProcessingId: () => {},
  actions: initialValues.actions,
  userStats: initialValues.userStats,
  loadingActions: initialValues.loadingActions,
  loadingUserStats: initialValues.loadingUserStats,
  errorActions: initialValues.errorActions,
  errorUserStats: initialValues.errorUserStats,
  refetchUserStats: () => new Promise(() => {}),
  refetchActions: () => new Promise(() => {}),
  isEditorModalOpen: initialValues.isEditorModalOpen,
  setIsEditorModalOpen: () => {}
} as ContextType

const EditorContext = React.createContext<ContextType>(context)
interface EditorContextProviderProps {
  children: JSX.Element
}

const POLL_INTERVAL = 30000

export const EditorContextProvider = ({ children }: EditorContextProviderProps) => {
  const { isAdmin, isLegalAdvisor } = useContext(UserContext)
  const [processingId, setProcessingId] = useState<string | undefined>(initialValues.processingId)
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false)
  const [pollInterval, setPollInterval] = useState(POLL_INTERVAL)
  const {
    data: userStatsData,
    loading: loadingUserStats,
    error: errorUserStats,
    refetch: refetchUserStats
  } = useQuery(USER_STATS_QUERY, {
    fetchPolicy: 'network-only',
    ssr: false,
    pollInterval,
    variables: {
      isLegalAdvisor: isAdmin || isLegalAdvisor,
      isAdmin
    }
  })

  // Manually starting polling because it can not be stopped otherwise
  // https://github.com/apollographql/apollo-client/issues/7221
  useEffect(() => {
    if (!isEditorModalOpen) {
      setPollInterval(POLL_INTERVAL)
      console.debug('Polling started')
    } else {
      setPollInterval(0)
      console.debug('Polling stopped')
    }

    return () => {
      setPollInterval(0)
      console.debug('Polling stopped, cleanup')
    }
  }, [isEditorModalOpen])

  useIdleTimer({
    timeout: 1000 * 3,

    onIdle: () => {
      setPollInterval(0)
    },
    onActive: () => {
      if (!isEditorModalOpen) {
        setPollInterval(POLL_INTERVAL)
      }
    },
    debounce: 2000
  })

  const {
    data: actionsData,
    loading: loadingActions,
    error: errorActions,
    refetch: refetchActions
  } = useQuery(ACTIONS_QUERY, {
    skip: !processingId || processingId.length <= 0,
    variables: {
      processingId,
      sortBy: {
        attribute: 'date',
        direction: 'desc'
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (!isEditorModalOpen) {
      refetchUserStats()
    }
  }, [processingId, refetchUserStats, isEditorModalOpen])
  useEffect(() => {
    if (processingId && !isEditorModalOpen) {
      refetchActions()
    }
  }, [processingId, refetchActions, isEditorModalOpen])

  const [userStats, setUserStats] = useState(undefined)
  useEffect(() => {
    if (userStatsData?.userStats && !isEditorModalOpen) {
      setUserStats(userStatsData?.userStats)
    }
  }, [setUserStats, userStatsData, isEditorModalOpen])

  const [actions, setActions] = useState([])
  useEffect(() => {
    if (actionsData?.actions?.collection && !isEditorModalOpen) {
      setActions(actionsData?.actions?.collection)
    }
  }, [setActions, actionsData, isEditorModalOpen])

  return (
    <EditorContext.Provider
      value={{
        processingId,
        setProcessingId,
        loadingActions,
        loadingUserStats,
        errorActions,
        errorUserStats,
        actions,
        userStats,
        refetchActions,
        refetchUserStats,
        isEditorModalOpen,
        setIsEditorModalOpen
      }}
    >
      {children}
    </EditorContext.Provider>
  )
}

export default EditorContext
