import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { Customer } from '../../../utils/fixtures'

interface NoMailReasonProps {
  noMailSentReason: string
  visibleForPolicyholder: boolean
  alternativeText?: boolean
}

const getNoMailSendingReason = (values: any, t: any, policyholder?: Customer) => {
  const returnArray: string[] = []
  if (!(values?.email || values?.policyholderEmail) && !policyholder?.email) {
    returnArray.push(t('common:missingEmail'))
  }
  if (!(values?.mobileNumber || values?.policyholderMobileNumber) && !policyholder?.mobileNumber) {
    returnArray.push(t('common:missingMobileNumber'))
  }
  if (
    values?.communicationViaCustomerPortal !== 'yes' &&
    policyholder?.communicationViaCustomerPortal !== 'yes'
  ) {
    returnArray.push(t('common:noCommunicationViaCustomerPortal'))
  }
  return returnArray.length ? returnArray.join('\n') : ''
}

type NoMailReasonType = {
  getNoMailSendingReason: (item: any, t: any, policyholder?: Customer) => string
}

export const NoMailReason: React.FC<NoMailReasonProps> & NoMailReasonType = ({
  noMailSentReason,
  visibleForPolicyholder,
  alternativeText
}) => {
  const { t } = useTranslation('common')
  return visibleForPolicyholder && noMailSentReason ? (
    <Grid item xs={12} md={12} sx={{ display: 'flex', mb: 3, mt: 1 }} justifyContent="center">
      <Box
        sx={{
          p: 1,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          width: 'fit-content',
          border: '2px solid red',
          borderRadius: '5px'
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {alternativeText ? t('common:noMailSendingReason') : t('common:noMailSentReason')}
        </Typography>
        <Typography variant="body1" color="error" sx={{ whiteSpace: 'pre-line' }}>
          {noMailSentReason.replace(/\\n/g, '\n')}
        </Typography>
      </Box>
    </Grid>
  ) : null
}

NoMailReason.getNoMailSendingReason = getNoMailSendingReason
export default NoMailReason
