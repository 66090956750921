import React from 'react'
import PropTypes from 'prop-types'
import { Switch as MuiSwitch, SwitchProps } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.petrol,
    '&:hover': {
      backgroundColor: alpha(theme.palette.petrol, theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.petrol
  }
}))

export interface CustomSwitchProps extends SwitchProps {
  small?: boolean
}

const CustomSwitch = React.forwardRef<HTMLButtonElement, CustomSwitchProps>(
  ({ small, ...props }, ref) =>
    small ? (
      <StyledSwitch disableRipple size="small" ref={ref} {...props} />
    ) : (
      <StyledSwitch disableRipple ref={ref} {...props} />
    )
)

CustomSwitch.propTypes = {
  small: PropTypes.bool
}
CustomSwitch.defaultProps = {
  small: false
}
export default CustomSwitch
