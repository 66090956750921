import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isLogin } from '../utils/helper'

const PublicRoute = ({ component: Component, restricted, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLogin() && restricted ? (
        <Redirect to="/dashboard" />
      ) : (
        <Suspense fallback={null}>
          <Component {...props} />
        </Suspense>
      )
    }
  />
)

PublicRoute.propTypes = {
  component: PropTypes.object,
  restricted: PropTypes.bool
}

PublicRoute.defaultProps = {
  restricted: false,
  component: undefined
}

export default PublicRoute
