import React, { useState } from 'react'
import { Typography, CircularProgress } from '@mui/material'
import { debounce } from 'lodash'
import { useFetchExport } from '../../../utils/hooks'
import { Button } from '../../atoms'

interface ExportButtonOptions {
  headers?: any
  url?: string
}

interface ExportButtonProps {
  options?: ExportButtonOptions
  disabled?: boolean
  helperText?: string
  label?: string
}

const ExportButton: React.FC<ExportButtonProps> = ({ options, disabled, helperText, label }) => {
  const { headers, url } = options || {}
  const [load, setLoad] = useState(false)
  const [{ error, loading }, doFetch] = useFetchExport({ headers })
  if (error) console.error(error)
  const throttledFetch = debounce(
    () => {
      doFetch(url)
      if (!loading) setLoad(false)
    },
    1000,
    { trailing: true, maxWait: 5000, leading: false }
  )

  return (
    <>
      <Button
        color="primary"
        startIcon={load ? <CircularProgress size={20} /> : undefined}
        fullWidth
        disabled={disabled || load}
        onClick={() => {
          setLoad(true)
          throttledFetch()
        }}
      >
        {label}
      </Button>
      {helperText && (
        <Typography
          variant="caption"
          sx={{ paddingTop: 1, display: 'flex', margin: '0px 14px', width: '100%' }}
        >
          {helperText}
        </Typography>
      )}
    </>
  )
}

export default ExportButton
