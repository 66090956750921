import React from 'react'
import { CircularProgress, Backdrop } from '@mui/material'

interface LoadingProps {
  loading: boolean
}

const Loading: React.FC<LoadingProps> = ({ loading }) => (
  <Backdrop sx={{ zIndex: 981, color: '#fff' }} open={loading}>
    <CircularProgress color="inherit" />
  </Backdrop>
)

export default Loading
