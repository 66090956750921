import React from 'react'
import { Box, Typography } from '@mui/material'

interface HeaderProps {
  title?: string | null | React.ReactElement
  icon?: React.ReactElement
  extra?: React.ReactElement
}

const Header: React.FC<HeaderProps> = ({ title, icon, extra }) => (
  <Box
    sx={theme => ({
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    })}
  >
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
          marginBottom: '5px'
        }
      })}
    >
      {icon && <Box sx={{ display: 'flex', marginRight: '5px' }}>{icon}</Box>}
      <Typography>{title}</Typography>
    </Box>
    <Box
      sx={theme => ({
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          flexDirection: 'column'
        }
      })}
    >
      {extra}
    </Box>
  </Box>
)

Header.defaultProps = {
  title: null,
  icon: undefined,
  extra: undefined
}

export default Header
