import theme from '../../../utils/theme'

const classes = {
  container: {
    color: theme?.palette?.secondary?.main,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  uploader: {
    cursor: 'pointer',
    border: `2px dashed ${theme?.palette?.grey?.['200']}`,
    borderRadius: 4,
    padding: theme?.spacing?.(2)
  },
  filelist: {
    position: 'relative',
    border: `1px solid ${theme?.palette?.grey?.['200']}`,
    borderRadius: 4,
    marginTop: theme?.spacing?.(2),
    padding: theme?.spacing?.(2)
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  uploadButton: {
    paddingTop: theme?.spacing?.(1)
  },
  newFile: {
    paddingBottom: theme?.spacing?.(2),
    color: theme?.palette?.secondary?.main
  },
  disabled: {
    color: theme?.palette?.grey[100],
    cursor: 'not-allowed'
  },
  noFileText: {
    color: theme?.palette?.error?.main,
    paddingTop: theme?.spacing?.(2),
    fontSize: '0.625rem'
  }
}

export default classes
