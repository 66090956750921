import { Avatar as MuiAvatar } from '@mui/material'
import { styled } from '@mui/material/styles'

const SmallAvatar = styled(MuiAvatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3)
}))

const RegularAvatar = styled(MuiAvatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7)
}))

interface AvatarProps {
  alt: string
  src?: string | undefined
  small?: boolean
  onClick?: () => void
}

const letterAvatar = (name?: string, propSize: number = 60, fill: string = '#4b5563') => {
  try {
    const nameSplit = String(name).toUpperCase().split(' ')
    let initials
    let canvas
    let size = propSize

    if (nameSplit.length === 1) {
      initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?'
    } else {
      initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0)
    }

    if (window.devicePixelRatio) {
      size *= window.devicePixelRatio
    }

    canvas = document.createElement('canvas')
    canvas.width = size
    canvas.height = size
    const context = canvas?.getContext('2d')
    if (context) {
      context.fillStyle = '#FFF'
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.font = `${Math.round(canvas.width / 2)}px Arial`
      context.textAlign = 'center'
      context.fillStyle = fill
      context?.fillText(initials, size / 2, size / 1.5)
    }
    const dataURI = canvas?.toDataURL() || null
    canvas = null

    return dataURI
  } catch (error) {
    // only needed for tests 'jest has no access to canvas'
    console.error('error', error)
    return undefined
  }
}

const Avatar: React.FC<AvatarProps> = ({
  alt = '',
  src = letterAvatar(alt) || undefined,
  small,
  onClick
}) => {
  if (small)
    return (
      <SmallAvatar
        src={src}
        alt={alt}
        {...(onClick ? { sx: { cursor: 'pointer' }, onClick } : {})}
      />
    )
  return (
    <RegularAvatar
      src={src}
      alt={alt}
      {...(onClick ? { sx: { cursor: 'pointer' }, onClick } : {})}
    />
  )
}

export default Avatar
