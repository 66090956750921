import React from 'react'
import PropTypes from 'prop-types'
import { Box, Chip as MaterialChip } from '@mui/material'
import { styled } from '@mui/material/styles'

const DotIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: 6,
  width: 6,
  marginLeft: '10px !important',
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[500]
}))

const InactiveChip = styled(MaterialChip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.grey[500]
}))

const TaggedChip = styled(MaterialChip)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.grey[500],
  color: 'white',
  height: 'unset',
  '& *': {
    padding: '1px 4px'
  }
}))

const Chip = ({ variant, dot, icon, label, onDelete }) => {
  if (variant === 'tagged') {
    return <TaggedChip label={label} icon={dot ? DotIcon : icon} onDelete={onDelete} />
  }
  return <InactiveChip label={label} icon={dot ? DotIcon : icon} onDelete={onDelete} />
}

Chip.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['inactive', 'tagged']),
  dot: PropTypes.bool,
  icon: PropTypes.node,
  onDelete: PropTypes.func
}
Chip.defaultProps = {
  variant: 'inactive',
  dot: false,
  icon: undefined,
  label: null,
  onDelete: undefined
}

export default Chip
