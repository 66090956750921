import React, { useEffect, useState, forwardRef } from 'react'
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText
} from '@mui/material'

interface RadioItem {
  value: string
  label: string
  children?: string
}

interface RadioButtonGroupProps {
  defaultValue?: string
  disabled?: boolean
  error?: any
  helperText?: string
  items?: RadioItem[]
  label?: string
  value?: string
  name?: string
  onChange?(value: string | undefined): void
  id?: string
}

const RadioButtonGroup = forwardRef<any, RadioButtonGroupProps>(
  (
    {
      defaultValue,
      disabled,
      error,
      helperText,
      id,
      items,
      label,
      name,
      onChange,
      value: propValue
    },
    ref
  ) => {
    const [value, setValue] = useState(propValue || defaultValue)
    useEffect(() => {
      setValue(propValue)
    }, [propValue])

    const handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void = ({ target }) => {
      const { value: newValue } = target
      setValue(newValue)
      if (onChange) onChange(newValue)
    }

    return (
      <FormControl component="fieldset" disabled={disabled}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup id={id} aria-label={name} ref={ref} value={value} onChange={handleChange}>
          {items?.map(item => (
            <div key={item.label}>
              <div>
                <FormControlLabel
                  key={item.label}
                  {...item}
                  control={
                    <Radio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'petrol'
                        },
                        '&.Mui-disabled': {
                          color: 'grey.300'
                        }
                      }}
                    />
                  }
                />
              </div>
              {item.value === value && item.children ? <div>{item.children}</div> : null}
            </div>
          ))}
        </RadioGroup>
        <FormHelperText error={!!error}>{helperText}</FormHelperText>
      </FormControl>
    )
  }
)

export default RadioButtonGroup
