import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Box, Dialog, DialogContent, DialogActions, Typography } from '@mui/material'
import { OutlinedCross } from '../../../assets/icons'
import { Button, Input } from '../../atoms'
import MessageBox from '../MessageBox'

const SMSButton = ({ mutation, label, value, disabled, options, dialogContent, helperText }) => {
  const { t } = useTranslation(['common', 'sms'])
  const [smsText, setSmsText] = useState(dialogContent?.message)
  const [open, setOpen] = useState(false)
  const [notificationVisible, setNotificationVisible] = useState(false)
  const [submitMutation] = useMutation(mutation, {
    variables: {
      ...options?.variables,
      smsText
    },
    onCompleted: () => {
      setOpen(false)
      setNotificationVisible({
        variant: 'success',
        title: t('success'),
        message: t('sms:success')
      })
      dialogContent?.callback?.(smsText)
    },
    onError: error => {
      setOpen(false)
      setNotificationVisible({ variant: 'error', title: t('error'), message: error?.message })
    }
  })
  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} fullWidth disabled={disabled}>
        {label}
      </Button>

      {helperText && (
        <Typography
          variant="caption"
          sx={{ paddingTop: 1, display: 'flex', margin: '0px 14px', width: '100%' }}
        >
          {helperText}
        </Typography>
      )}
      <Typography
        variant="caption"
        sx={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '5px' }}
      >
        {value?.[0]}
      </Typography>
      <Dialog open={open} data-testid="sms-modal">
        <DialogContent style={{ maxWidth: 500 }}>
          <>
            <Typography variant="h6">{dialogContent?.title}</Typography>
            <Typography variant="caption" sx={{ paddingTop: 1 }}>
              {dialogContent?.subtitle}
            </Typography>
            <Typography variant="body2" component="div">
              <Input
                sx={{ whiteSpace: 'pre-line', paddingTop: 1 }}
                value={smsText}
                multiline
                fullWidth
                onChange={({ target: { value: smsValue } }) => setSmsText(smsValue)}
              />
            </Typography>
          </>
        </DialogContent>
        <DialogActions>
          <Box sx={{ marginRight: '39px', marginBottom: '42px' }}>
            <Button
              color="secondary"
              sx={{ marginRight: '15px' }}
              onClick={() => setOpen(false)}
              startIcon={<OutlinedCross />}
            >
              {t('common:abort')}
            </Button>
            <Button color="primary" onClick={submitMutation}>
              {t('sms:sendNow')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <MessageBox
        open={notificationVisible}
        onClose={() => setNotificationVisible(false)}
        autoHide
        variant={notificationVisible?.variant}
        title={notificationVisible?.title}
        message={notificationVisible?.message}
      />
    </>
  )
}

SMSButton.propTypes = {
  mutation: PropTypes.object.isRequired,
  options: PropTypes.object,
  label: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  dialogContent: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    message: PropTypes.string,
    callback: PropTypes.func
  }),
  helperText: PropTypes.string
}

SMSButton.defaultProps = {
  value: [],
  disabled: false,
  options: undefined,
  dialogContent: undefined,
  helperText: undefined
}

export default SMSButton
