import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Box, Tooltip, Typography } from '@mui/material'

interface CaseTitleProps {
  tooltip?: boolean
  tooltipContent?: string
  title: string | null | React.ReactElement
  subtitle?: string | null | React.ReactElement
}

const CaseTitle: React.FC<CaseTitleProps> = ({ tooltip, tooltipContent, title, subtitle }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box
      sx={theme => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          fontSize: '1.5rem'
        }
      })}
    >
      {tooltip && (
        <Tooltip title={tooltipContent}>
          <AccountCircleIcon
            sx={theme => ({
              marginRight: '8px',
              fill: theme.palette.primary.main,
              ':hover': { fill: theme.palette.petrol }
            })}
          />
        </Tooltip>
      )}

      {title}
    </Box>
    {subtitle && (
      <Typography color="primary" sx={{ pt: 0, marginLeft: '35px' }} variant="h4">
        {subtitle}
      </Typography>
    )}
  </Box>
)

export default CaseTitle
