import React from 'react'
import PropTypes from 'prop-types'
import { Select, SelectProps } from '@mui/material'

export interface CustomSelectProps extends Omit<SelectProps, 'component'> {
  maxWidth?: string | number
  minWidth?: string | number
}
const CustomSelect: React.FC<CustomSelectProps> = ({ maxWidth, minWidth, ...props }) => (
  <Select
    sx={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth,
      minWidth,
      '&:focus': {
        backgroundColor: 'transparent'
      },
      border: '10px solid red !important'
    }}
    {...props}
  />
)

CustomSelect.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

CustomSelect.defaultProps = {
  maxWidth: 180,
  minWidth: 100
}

export default CustomSelect
