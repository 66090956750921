import React from 'react'
import { Grid } from '@mui/material'

interface FooterProps {
  rightActions?: React.ReactNode
}

const Footer: React.FC<FooterProps> = ({ rightActions }) => (
  <Grid container justifyContent="flex-end">
    <Grid item xs={8}>
      {rightActions}
    </Grid>
  </Grid>
)

Footer.defaultProps = {
  rightActions: null
}

export default Footer
