const CREATOR = 'creator'
const ADMIN = 'admin'
const EDITOR = 'user'
const LEGAL_ADVISOR = 'legal_advisor'
// add roles to disable fields
// TODO: add objects to disable views or maybe separated?

export type Role = 'creator' | 'admin' | 'user' | 'legal_advisor'

export interface RestrictionElement {
  name: string
  roles: Role[]
}

export interface VisibilityElement {
  role: Role
  visibility: string
}

export type RestrictionKey =
  | 'evaluationElements'
  | 'customerSatisfactionElements'
  | 'stateChangeElements'
  | 'caseInformationElements'
  | 'policyholderElements'
  | 'conflictPartnerElements'
  | 'contactPersonElements'
  | 'processingIntentionElements'
  | 'processingSolutionElements'
  | 'registrationElements'
  | 'actionElements'
  | 'reportEvaluation'
  | 'reportSettlement'
  | 'reportPaymentElements'
  | 'processingCoverage'
  | 'processingProcessing'
  | 'processingCompletion'
  | 'caseEditTabs'
  | 'menuItems'
  | 'routes'
  | 'entries'

export type VisibilityKey = 'caseState' | 'processingState'

export type Restrictions = {
  [key in RestrictionKey]: RestrictionElement[]
}
export type Visibilities = {
  [key in VisibilityKey]: VisibilityElement[]
}

export interface IRestriction extends Restrictions, Visibilities {
  defaultEntry: string
}

const restrictions: () => IRestriction = () => ({
  evaluationElements: [{ name: 'statusReportSentAt', roles: [CREATOR, EDITOR, LEGAL_ADVISOR] }],
  reportPaymentElements: [
    { name: 'costsSettledWithRsvAt', roles: [CREATOR, EDITOR, LEGAL_ADVISOR] },
    { name: 'income', roles: [CREATOR, EDITOR, LEGAL_ADVISOR] },
    { name: 'additionalIncome', roles: [CREATOR, EDITOR, LEGAL_ADVISOR] },
    { name: 'invoiceSettlementComments', roles: [CREATOR, EDITOR, LEGAL_ADVISOR] }
  ],
  customerSatisfactionElements: [
    { name: 'customerRating', roles: [CREATOR, EDITOR, LEGAL_ADVISOR] },
    { name: 'customerComments', roles: [CREATOR, EDITOR, LEGAL_ADVISOR] }
  ],
  stateChangeElements: [
    { name: 'caseState', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'processingState', roles: [EDITOR, LEGAL_ADVISOR] }
  ],
  caseInformationElements: [
    { name: 'stateChangeElements', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'assignedToM2At', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'insuranceId', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'projectId', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'claimNumber', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'policyNumber', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'caseComments', roles: [] },
    { name: 'hint', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'rsvLegalAreaId', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'm2LegalAreaId', roles: [EDITOR, LEGAL_ADVISOR] },
    { name: 'disputeValue', roles: [] },
    { name: 'deductible', roles: [] },
    { name: 'noDetails', roles: [] },
    { name: 'rsvStatus', roles: [EDITOR, LEGAL_ADVISOR] }
  ],
  policyholderElements: [
    { name: 'policyholderAttributes.salutation', roles: [] },
    { name: 'policyholderAttributes.lastname', roles: [] },
    { name: 'policyholderAttributes.firstname', roles: [] },
    { name: 'policyholderAttributes.birthDate', roles: [] },
    { name: 'policyholderAttributes.company', roles: [] },
    { name: 'policyholderAttributes.street', roles: [] },
    { name: 'policyholderAttributes.houseNumber', roles: [] },
    { name: 'policyholderAttributes.postalCode', roles: [] },
    { name: 'policyholderAttributes.place', roles: [] },
    { name: 'policyholderAttributes.phoneNumber', roles: [] },
    { name: 'policyholderAttributes.mobileNumber', roles: [] },
    { name: 'policyholderAttributes.email', roles: [] },
    { name: 'policyholderAttributes.comments', roles: [EDITOR, LEGAL_ADVISOR] }
  ],
  conflictPartnerElements: [
    { name: 'conflictPartnerAttributes.salutation', roles: [] },
    { name: 'conflictPartnerAttributes.lastname', roles: [] },
    { name: 'conflictPartnerAttributes.firstname', roles: [] },
    { name: 'conflictPartnerAttributes.birthDate', roles: [] },
    { name: 'conflictPartnerAttributes.company', roles: [] },
    { name: 'conflictPartnerAttributes.street', roles: [] },
    { name: 'conflictPartnerAttributes.houseNumber', roles: [] },
    { name: 'conflictPartnerAttributes.postalCode', roles: [] },
    { name: 'conflictPartnerAttributes.place', roles: [] },
    { name: 'conflictPartnerAttributes.phoneNumber', roles: [] },
    { name: 'conflictPartnerAttributes.mobileNumber', roles: [] },
    { name: 'conflictPartnerAttributes.email', roles: [] },
    {
      name: 'conflictPartnerAttributes.comments',
      roles: [EDITOR, LEGAL_ADVISOR]
    }
  ],
  contactPersonElements: [
    { name: 'contactPersonAttributes.salutation', roles: [] },
    { name: 'contactPersonAttributes.lastname', roles: [] },
    { name: 'contactPersonAttributes.firstname', roles: [] },
    { name: 'contactPersonAttributes.phoneNumber', roles: [] },
    { name: 'contactPersonAttributes.mobileNumber', roles: [] },
    { name: 'contactPersonAttributes.email', roles: [] },
    { name: 'contactPersonAttributes.relationToPolicyholder', roles: [] },
    { name: 'contactPersonAttributes.comments', roles: [] }
  ],
  processingIntentionElements: [
    { name: 'goalPolicyholderWants', roles: [] },
    { name: 'goalConflictPartnerWants', roles: [] }
  ],
  processingSolutionElements: [
    { name: 'goalPolicyholderSolutions', roles: [] },
    { name: 'goalConflictPartnerSolutions', roles: [] }
  ],
  registrationElements: [
    { name: 'source', roles: [CREATOR, EDITOR, LEGAL_ADVISOR] },
    { name: 'creatorComments', roles: [EDITOR, LEGAL_ADVISOR] }
  ],
  actionElements: [
    { name: 'smsButton', roles: [] },
    { name: 'exportButton', roles: [] }
  ],
  reportEvaluation: [
    {
      name: 'processingsAttributes.statusReportSentAt',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.comments',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.feedback',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.additionalComments',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    }
  ],
  reportSettlement: [
    {
      name: 'processingsAttributes.cost',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.additionalCost',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.costsCoveredByKp',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.receivedPayment',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.settlementComments',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.costsSettledWithOperatorAt',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    }
  ],
  processingCoverage: [
    { name: 'processingsAttributes.coverage', roles: [] },
    {
      name: 'processingsAttributes.policyholderContactedAt',
      roles: []
    },
    { name: 'processingsAttributes.coverageRequestedAt', roles: [] },
    { name: 'processingsAttributes.feedbackRsvAt', roles: [] },
    { name: 'processingsAttributes.date1', roles: [CREATOR] },
    { name: 'processingsAttributes.date2', roles: [CREATOR] },
    { name: 'processingsAttributes.coverageComments', roles: [] }
  ],
  processingProcessing: [
    {
      name: 'processingsAttributes.assignedProcessingTypeId',
      roles: []
    },
    {
      name: 'processingsAttributes.agentId',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.intendedProcessingTypeId',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.assignedAt',
      roles: [EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.assignedFromId',
      roles: [EDITOR, LEGAL_ADVISOR, CREATOR]
    },
    {
      name: 'processingsAttributes.canBeginAt',
      roles: [EDITOR, LEGAL_ADVISOR]
    },
    { name: 'processingsAttributes.acceptedAt', roles: [CREATOR] },
    { name: 'processingsAttributes.firstCallAt', roles: [CREATOR] },
    { name: 'processingsAttributes.processingComments', roles: [CREATOR] }
  ],
  processingCompletion: [
    {
      name: 'processingsAttributes.closedAt',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.actualProcessingTypeId',
      roles: []
    },
    {
      name: 'processingsAttributes.documentsWereSent',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.settlementRate',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.settlementType',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.closingType',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.result',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.closedByM2',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.closedByRsv',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    },
    {
      name: 'processingsAttributes.closingComments',
      roles: [CREATOR, EDITOR, LEGAL_ADVISOR]
    }
  ],
  caseEditTabs: [
    { name: 'overview', roles: [] },
    { name: 'caseEvaluation', roles: [] },
    { name: 'caseProcessing', roles: [] }
  ],
  // possible values hidden, disabled
  caseState: [
    { role: CREATOR, visibility: 'disabled' },
    { role: EDITOR, LEGAL_ADVISOR, visibility: 'disabled' },
    { role: LEGAL_ADVISOR, visibility: 'disabled' }
  ],
  processingState: [
    { role: CREATOR, visibility: 'disabled' },
    { role: EDITOR, visibility: 'disabled' },
    { role: LEGAL_ADVISOR, visibility: 'disabled' }
  ],
  menuItems: [
    { name: 'dashboard', roles: [CREATOR] },
    { name: 'cases', roles: [] },
    { name: 'cases-creator', roles: [EDITOR, ADMIN, LEGAL_ADVISOR] },
    { name: 'mediatorInfo', roles: [CREATOR] },
    { name: 'ki-mediation', roles: [CREATOR, EDITOR] },
    { name: 'ki-rechtsberatung', roles: [CREATOR, EDITOR] },
    { name: 'logout', roles: [] },
    { name: 'profile', roles: [] }
  ],
  routes: [
    { name: '/', roles: [] },
    { name: '/login', roles: [] },
    { name: '/dashboard', roles: [CREATOR] },
    { name: '/cases', roles: [] },
    { name: '/cases/:id', roles: [] },
    { name: '/cases/new', roles: [] },
    { name: '/cases/new-legal-advice', roles: [EDITOR] },
    { name: '/cases-creator/', roles: [ADMIN, EDITOR, LEGAL_ADVISOR] },
    { name: '/cases-creator/:id', roles: [ADMIN, EDITOR, LEGAL_ADVISOR] },
    { name: '/mediator-info', roles: [CREATOR] },
    { name: '/mediator-info/edit-blog-post/:id', roles: [] },
    { name: '/profile', roles: [] }
  ],
  // set User Entrypoint set only 1 path per role
  entries: [
    { name: '/dashboard', roles: [ADMIN, EDITOR, LEGAL_ADVISOR] },
    { name: '/cases', roles: [CREATOR] }
  ],
  defaultEntry: '/cases'
})

export default restrictions
