import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { TrashCan } from '../../../assets/icons'
import { DELETE_SELECTION_ITEM_MUTATION } from '../../../graphql/mutations'
import Divider from '../Divider'

const ColumnSelectionItem = ({ item, onDelete, onSelect }) => {
  const [deleteSelectionItem] = useMutation(DELETE_SELECTION_ITEM_MUTATION, {
    onCompleted: onDelete
  })
  const onDeleteButtonClick = () => {
    deleteSelectionItem({
      variables: {
        id: item.id
      }
    })
  }
  const onClick = () => {
    const columns = JSON.parse(item.columns)
    onSelect(columns, item.title)
  }
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box
          tabIndex={0}
          role="link"
          onKeyUp={onClick}
          onClick={onClick}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography>{item.title}</Typography>
          <Typography variant="caption" sx={{ color: 'grey.500' }}>
            {`${moment(item.createdAt).format('DD.MM.YYYY')} - ${item.user?.name}`}
          </Typography>
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
            padding: 1,
            minWidth: '40px',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '50%',
            alignSelf: 'center',
            '&:hover': {
              backgroundColor: 'grey.50'
            }
          }}
        >
          <TrashCan onClick={onDeleteButtonClick} />
        </Box>
      </Box>
      <Divider my={6} />
    </Box>
  )
}
ColumnSelectionItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    columns: PropTypes.array.isRequired
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default ColumnSelectionItem
