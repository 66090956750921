import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { DragIcon } from '../../../assets/icons'
import { Divider, Switch } from '../../atoms'

const ListItem = React.memo(({ id, index, label, defaultChecked, onChange, disabled, last }) => (
  <>
    <Draggable draggableId={id} index={index}>
      {provided => (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            backgroundColor: 'common.white',
            alignItems: 'center',
            boxSizing: 'border-box',
            padding: '12px'
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box sx={{ cursor: 'move', display: 'flex' }}>
            <DragIcon />
            <Box sx={{ paddingLeft: '16px' }}>{label}</Box>
          </Box>
          <Switch checked={defaultChecked} onChange={onChange} disabled={disabled} />
        </Box>
      )}
    </Draggable>
    {!last ? <Divider my={0} mx={12} /> : null}
  </>
))

ListItem.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number,
  defaultChecked: PropTypes.bool,
  last: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
ListItem.defaultProps = {
  label: null,
  id: null,
  index: null,
  defaultChecked: null,
  last: null,
  disabled: false,
  onChange: () => {}
}

export default ListItem
