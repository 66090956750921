import React from 'react'
import { Box, Drawer, IconButton } from '@mui/material'
import { ReactComponent as ArrowLeft } from '../../../assets/icons/left-arrow.svg'
import { ReactComponent as ArrowRight } from '../../../assets/icons/right-arrow.svg'

export interface SideBarProps {
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode
  openIcon?: React.ReactNode
  closeIcon?: React.ReactNode
  drawerWidth?: number
  drawerWidthClosed?: number
}

const SideBar: React.FC<SideBarProps> = ({
  children,
  closeIcon,
  openIcon,
  open,
  setOpen,
  drawerWidth = 380,
  drawerWidthClosed = 60
}) => {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { open } as { open: boolean })
    }
    return child
  })

  const handleDrawer = () => {
    setOpen(!open)
  }

  return (
    <Box>
      <Drawer variant="permanent">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          sx={{
            zIndex: 981,
            position: 'fixed',
            top: '186px',
            left: open ? `${drawerWidth - 16}px` : `${drawerWidthClosed - 16}px`
          }}
          size="large"
        >
          {open ? closeIcon || <ArrowLeft /> : openIcon || <ArrowRight />}
        </IconButton>
        <Box
          sx={{
            position: 'relative',
            flex: 1,
            whiteSpace: 'nowrap',
            width: open ? `${drawerWidth}` : `${drawerWidthClosed}`,
            backgroundColor: 'petrol',
            overflowY: 'unset'
          }}
        >
          {childrenWithProps}
        </Box>
      </Drawer>
    </Box>
  )
}

export default SideBar
