import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface IControlledElement {
  name: string
  children: React.ReactElement
  onChange?: (e: any) => void
}

const ControlledElement: React.FC<IControlledElement> = ({
  name,
  children,
  onChange: onPropChange
}) => {
  const { control } = useFormContext()
  const { t } = useTranslation('common')

  const handleFieldChange = (e: any, onChange: (e: any) => void) => {
    if (onPropChange) {
      onPropChange(e)
    }
    onChange(e)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref, ...rest }, fieldState }) =>
        React.cloneElement(children, {
          onChange: (e: any) => handleFieldChange(e, onChange),
          value,
          ref,
          ...rest,
          ...fieldState,
          ...(fieldState?.error ? { helperText: t(`common:${fieldState?.error?.message}`) } : {})
        })
      }
    />
  )
}

export default ControlledElement
