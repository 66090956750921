import React, { useEffect, useState, forwardRef } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material'

interface CheckBoxProps {
  checked?: boolean
  value?: boolean
  onChange?(value: boolean, name: string): void
  name?: string
  label?: string
  className?: string
  helperText?: string
  disabled?: boolean
  error?: boolean
  id?: string
}

const CheckBox = forwardRef<any, CheckBoxProps>(
  (
    {
      className,
      checked: propChecked = false,
      value = false,
      onChange,
      name,
      id,
      label,
      helperText,
      disabled,
      error
    },
    ref
  ) => {
    const [checked, setChecked] = useState(false)
    useEffect(() => {
      setChecked(propChecked || value)
    }, [propChecked, value])

    const handleChange = (e: React.ChangeEvent, ckd: boolean) => {
      setChecked(ckd)
      if (onChange) onChange(ckd, (e.target as HTMLInputElement).name)
    }

    return (
      <>
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              id={id}
              ref={ref}
              checked={checked}
              color="primary"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              onChange={handleChange}
              name={name}
              disabled={disabled}
            />
          }
          label={label}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </>
    )
  }
)

export default CheckBox
