import React from 'react'
import { useTranslation } from 'react-i18next'
import { AttachFile, CloudDownload, Delete } from '@mui/icons-material'
import { Box, Link, Tooltip, Typography } from '@mui/material'
import { FileType } from '../../../utils/fixtures'

export interface FileListItemProps {
  file: FileType
  onDelete?(file: FileType, e: React.MouseEvent<Element, MouseEvent>): void
}

const FileListItem: React.FunctionComponent<FileListItemProps> = ({ file, onDelete }) => {
  const { t } = useTranslation('common')
  const [overflowed, setOverflowed] = React.useState(false)
  const textElement = React.useRef<null | HTMLDivElement>(null)
  React.useEffect(() => {
    setOverflowed(
      (textElement?.current?.scrollWidth || 0) > (textElement?.current?.clientWidth || 0)
    )
  }, [])
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: theme => theme?.palette?.grey?.[300],
        minWidth: 0
      }}
    >
      <AttachFile />
      <Typography
        sx={{
          pl: 1,
          pr: 1,
          color: theme => theme?.palette?.black,
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        <Tooltip
          sx={{ minWidth: 0 }}
          title={file?.filename || file?.path}
          disableHoverListener={!overflowed}
        >
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: 0
            }}
            ref={textElement}
          >
            {file?.filename || file?.path}
          </Box>
        </Tooltip>
      </Typography>
      {file?.attachmentUrl ? (
        <Link
          target="_blank"
          sx={{ pl: 1, color: theme => theme?.palette?.grey?.[300] }}
          href={file?.attachmentUrl}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <CloudDownload />
        </Link>
      ) : null}
      {file?.pageCount ? (
        <Typography
          sx={{ pl: 1, color: theme => theme?.palette?.grey?.[300], whiteSpace: 'nowrap' }}
        >
          {t('common:page', { count: file?.pageCount })}
        </Typography>
      ) : null}
      {onDelete ? (
        <Delete
          sx={{ pl: 1, fontSize: '2rem', cursor: 'pointer' }}
          onClick={e => onDelete(file, e)}
        />
      ) : null}
    </Box>
  )
}

export default FileListItem
