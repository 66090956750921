import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from 'react'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import { CASE_QUERY, INSURANCE_QUERY, PROCESSING_TYPES_QUERY } from '../../graphql/queries'
import { Insurance, Processing } from '../fixtures'
import UserContext from '../UserContext'

const initialValues = {
  m2Case: {},
  processing: {},
  refetch: () => {},
  id: '',
  processingId: '',
  processingType: {}
}

type ContextType = {
  processing?: Processing
  m2Case?: any
  setCase: Dispatch<SetStateAction<any>>
  id: string
  setId: Dispatch<SetStateAction<string>>
  processingId: string
  setProcessingId: Dispatch<SetStateAction<string>>
  refetch: (variables?: Partial<{ id: string }> | undefined) => Promise<ApolloQueryResult<any>>
  processingTypes: ProcessingTypes[]
  insurances: Insurance[]
}
const context = {
  processing: {},
  m2Case: initialValues.m2Case,
  setCase: () => {},
  id: initialValues.id,
  setId: () => {},
  processingId: initialValues.processingId,
  setProcessingId: () => {},
  refetch: () => new Promise(() => {}),
  processingTypes: [],
  insurances: []
} as ContextType

const CaseContext = React.createContext<ContextType>(context)
interface CaseContextProviderProps {
  children: JSX.Element
}

interface ProcessingTypes {
  id: string
  title: string
  cost: number
  callCenterLegalAdvice: boolean
  accessibleFor: string
}

export const CaseContextProvider = ({ children }: CaseContextProviderProps) => {
  const { isAdmin } = useContext(UserContext)
  const [m2Case, setCase] = useState<any>(initialValues.m2Case)
  const [processing, setProcessing] = useState<Processing | {}>(initialValues.processing)

  const [id, setId] = useState(initialValues.id)
  const [processingId, setProcessingId] = useState<string>(initialValues.processingId)
  const [processingTypes, setProcessingTypes] = useState<ProcessingTypes[]>([])
  const [insurances, setInsurances] = useState<Insurance[]>([])

  useQuery(INSURANCE_QUERY, {
    onCompleted: data => {
      setInsurances(data.insurances.collection)
    }
  })

  useQuery(PROCESSING_TYPES_QUERY, {
    onCompleted: data => {
      setProcessingTypes(data.processingTypes.collection)
    }
  })

  const { data, refetch } = useQuery(CASE_QUERY, {
    variables: { id, isAdmin },
    skip: !id
  })
  useEffect(() => {
    setCase({ ...data?.m2Case })
  }, [data, setCase])

  useEffect(() => {
    if (processingId) {
      setProcessing({
        ...(data?.m2Case.processings.find((p: Processing) => p.id === processingId) || {})
      })
    } else {
      setProcessing({})
    }
  }, [processingId, data?.m2Case?.processings, setProcessing])

  return (
    <CaseContext.Provider
      value={{
        m2Case,
        setCase,
        refetch,
        insurances,
        processing,
        processingId,
        processingTypes,
        setProcessingId,
        id,
        setId
      }}
    >
      {children}
    </CaseContext.Provider>
  )
}

export default CaseContext
