import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { Button, Text } from '../../atoms'

export interface InfoModalProps {
  title: string
  content: string
  open: boolean
}

const InfoModal: React.FC<InfoModalProps> = ({ title, content, open }) => {
  const { t } = useTranslation('common')
  const [openState, setOpenState] = useState<boolean>(open)
  const onOkay = () => {
    setOpenState(false)
  }

  useEffect(() => {
    setOpenState(open)
  }, [open])

  return (
    <Dialog open={openState}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Text>{content}</Text>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="end">
          <Grid>
            <Button
              onClick={() => {
                onOkay()
              }}
            >
              {t('ok')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default InfoModal
