import React, { FunctionComponent } from 'react'
import { CloudDownload } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'
import moment from 'moment'
import { FileType } from '../../../utils/fixtures'

export interface DownloadRowProps {
  file: FileType
}

const DownloadRow: FunctionComponent<DownloadRowProps> = ({ file }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {file?.attachmentUrl ? (
      <Link
        target="_blank"
        sx={{ display: 'flex', pl: 2, color: theme => theme?.palette?.petrol }}
        href={file?.attachmentUrl}
      >
        <CloudDownload />
      </Link>
    ) : null}
    <Typography sx={{ pl: 1, color: theme => theme?.palette?.black }}>
      {file?.filename || file?.attachmentFilename}
    </Typography>
    <Typography sx={{ pl: 2, color: theme => theme?.palette?.petrol }}>
      {moment(file?.createdAt).format('DD.MM.YYYY')}
    </Typography>
  </Box>
)

export default DownloadRow
