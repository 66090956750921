import React, { useState, useEffect } from 'react'
import { TextField, OutlinedTextFieldProps } from '@mui/material'
import { PhoneNumberInput, CustomNumber } from './components'

export interface InputProps extends OutlinedTextFieldProps {
  disabled?: boolean
  fullWidth?: boolean
  label?: string
  multiline?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  rows?: number
  showPassword?: boolean
  small?: boolean
  type?: 'default' | 'money' | 'password' | 'textfield' | 'phone'
  value?: string
  defaultValue?: string
  name?: string
  error?: any
  autoFocus?: boolean
  helperText?: string
  noPlaceholder?: boolean
  thickBorder?: boolean
}
type InputPropsType = Omit<InputProps, 'variant'>

const Input = React.forwardRef(
  (
    {
      thickBorder,
      disabled,
      fullWidth,
      label,
      multiline,
      onChange,
      rows,
      showPassword,
      small,
      type,
      value,
      defaultValue,
      name,
      error,
      autoFocus,
      helperText,
      noPlaceholder,
      ...props
    }: InputPropsType,
    ref
  ): JSX.Element => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
      setVisible(!!showPassword)
    }, [showPassword])

    interface IDefaultFieldProps {
      variant: 'outlined'
      disabled?: boolean
      autoFocus?: boolean
      label?: string
      name?: string
      onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      size?: 'small' | 'medium'
      value?: string
      defaultValue?: string
      fullWidth?: boolean
      multiline?: boolean
      rows?: number
      error?: any
      helperText?: string
      thickBorder?: boolean
    }

    const defaultFieldProps = {
      ...props,
      variant: 'outlined',
      disabled,
      autoFocus,
      label,
      name,
      onChange,
      size: small ? 'small' : 'medium',
      value,
      defaultValue,
      fullWidth,
      multiline,
      rows,
      error: !!error,
      helperText,
      ...(thickBorder
        ? {
            sx: (theme: any) => ({
              '& .MuiOutlinedInput-notchedOutline': {
                border: `5px solid ${theme?.palette?.petrol} !important`
              },
              '& .Mui-focused > .MuiOutlinedInput-notchedOutline ': {
                border: '2px solid #3b82f6 !important'
              }
            })
          }
        : {})
    } as IDefaultFieldProps

    interface IInputProps {
      endAdornment?: string
      inputComponent: React.FC
    }

    interface IMoneyFieldProps {
      InputProps: IInputProps
    }

    const moneyFieldProps = {
      InputProps: {
        endAdornment: '€',
        inputComponent: CustomNumber
      }
    } as IMoneyFieldProps

    const phoneFieldProps = {
      InputProps: {
        inputComponent: PhoneNumberInput
      },
      InputLabelProps: { shrink: true }
    }

    const passwordFieldProps = { type: visible ? 'test' : 'password' }

    if (type === 'money') {
      return <TextField inputRef={ref} {...defaultFieldProps} {...moneyFieldProps} />
    }

    if (type === 'password') {
      return <TextField inputRef={ref} {...defaultFieldProps} {...passwordFieldProps} />
    }
    if (type === 'phone') {
      return (
        <TextField
          inputRef={ref}
          {...defaultFieldProps}
          {...phoneFieldProps}
          sx={{
            '& .PhoneInput': { display: 'flex' },
            '& .PhoneInputInput': {
              border: 'none',
              fontFamily: 'fontFamily',
              font: 'inherit',
              color: 'currentColor'
            },
            '& .PhoneInputInput::focus-visible': {
              backgroundColor: 'lime'
            },
            '& .PhoneInput--focus input': {
              outline: 'none !important'
            }
          }}
        />
      )
    }

    return (
      <TextField
        inputRef={ref}
        {...defaultFieldProps}
        InputLabelProps={noPlaceholder ? { shrink: true } : undefined}
      />
    )
  }
)
Input.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  error: false,
  disabled: false,
  fullWidth: false,
  label: '',
  multiline: false,
  showPassword: false,
  small: true,
  autoFocus: false,
  type: 'default',
  helperText: undefined,
  noPlaceholder: false
}

export default Input
