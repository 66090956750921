const env = process.env.REACT_APP_ENV
const backendPort = process.env.REACT_APP_BACKEND_PORT || 3000
const devIP = process.env.REACT_APP_DEV_IP || 'localhost'

const appConfig = {
  apollo: {
    networkInterface: {
      development: `http://${devIP}:${backendPort}/graphql`,
      staging: 'https://admin-staging.m2-loesung.de/graphql',
      production: 'https://admin.m2-loesung.de/graphql'
    }[env],
    baseUrl: {
      development: `http://${devIP}:${backendPort}`,
      staging: 'https://admin-staging.m2-loesung.de',
      production: 'https://admin.m2-loesung.de'
    }[env]
  },
  actionCable: {
    networkInterface: {
      development: `ws://${devIP}:${backendPort}/cable`,
      staging: 'wss://admin-staging.m2-loesung.de/cable',
      production: 'wss://admin.m2-loesung.de/cable'
    }[env],
    baseUrl: {
      development: `ws://${devIP}:${backendPort}`,
      staging: 'wss://admin-staging.m2-loesung.de',
      production: 'wss://admin.m2-loesung.de'
    }[env]
  }
}

export default appConfig
