import React from 'react'
import PropTypes from 'prop-types'
import { IconButton as MuiIconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

const PrimaryIconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.common.black,
  '&:focus': {
    boxShadow: `0 0 0 1px ${theme.palette.common.white}, 0 0 0 3px ${'blueSoft'}`
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
    boxShadow: 'none'
  }
}))

const SecondaryIconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: theme.palette.secondary.main,
  '&:focus': {
    border: '1px solid white',
    boxShadow: `0 0 0 1px ${theme.palette.common.white}, 0 0 0 3px ${'blueSoft'}`
  },
  '&:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none'
  },
  '&:active': {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey[200]
  }
}))

interface IconButtonProps extends React.ComponentProps<typeof MuiIconButton> {
  color?: 'primary' | 'secondary'
  icon: React.ReactNode
}

const IconButton: React.FC<IconButtonProps> = ({ color, icon, disabled, onClick }) =>
  color === 'secondary' ? (
    <SecondaryIconButton color={color} disabled={disabled} onClick={onClick}>
      {icon}
    </SecondaryIconButton>
  ) : (
    <PrimaryIconButton color={color} disabled={disabled} onClick={onClick}>
      {icon}
    </PrimaryIconButton>
  )

IconButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  icon: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

IconButton.defaultProps = {
  color: 'primary',
  disabled: false,
  onClick: () => {}
}

export default IconButton
