import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { FileType } from '../../../../utils/fixtures'
import { FileListItem } from '../../../atoms'
import classes from '../styles'

interface IProps {
  files?: FileType[] | null
  onDelete?: (file: FileType, e: React.MouseEvent<Element, MouseEvent>) => void
}
const UploadedFiles: React.FunctionComponent<IProps> = ({ files, onDelete }) => {
  const { t } = useTranslation('common')

  return (
    <Box sx={classes.filelist}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          px: '5px',
          fontSize: '1rem',
          transformOrigin: 'top left',
          transform: 'translate(9px, -9px) scale(0.75)',
          background: 'white',
          color: theme => theme?.palette?.grey?.['300']
        }}
      >
        <Typography>{t('common:documents')}</Typography>
      </Box>
      {files?.map(file => {
        const { filename } = file
        return <FileListItem key={filename} file={file} onDelete={onDelete} />
      })}
    </Box>
  )
}

UploadedFiles.defaultProps = {
  files: [],
  onDelete: undefined
}

export default UploadedFiles
