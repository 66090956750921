import React from 'react'
import { Global, css, useTheme } from '@emotion/react'

const GlobalStyle = () => {
  const theme = useTheme()
  return (
    <Global
      styles={css`
        html {
          overflow: auto;
        }
        body {
          font-family: ${theme.fontFamily}, 'Fira Sans, sans-serif';
          margin: 0;
          font-weight: 300;
          color: ${theme.palette.black};
          padding-right: 0 !important;

          overflow: auto;
          -ms-overflow-style: none;
          scrollbar-width: thin;
        }
        header {
          padding-right: 0 !important;
        }
        #intro {
          & > .codex-editor--empty > .codex-editor__redactor {
            padding: 0 !important;
          }
          & > .codex-editor > .codex-editor__redactor {
            padding-bottom: 50px !important;
          }
          & > .ce-block,
          .ce-block__content,
          .ce-toolbar__content {
            max-width: 100% !important;
          }
          & > .cdx-block {
            max-width: 100% !important;
          }
        }

        .ellipsis {
          overflow: hidden;
          max-height: 60px;
          -webkit-line-clamp: 3;
          display: -webkit-box;
          text-overflow: ellipsis;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      `}
    />
  )
}

export default GlobalStyle
