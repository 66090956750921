import React from 'react'
import PropTypes from 'prop-types'
import { Divider as MDivider } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const Divider = ({ children, vertical, left, upper, my, mx }) => {
  const theme = useTheme()
  return vertical ? (
    <div style={{ width: '100%', height: '100%' }}>
      <MDivider orientation="vertical" variant="inset" />
    </div>
  ) : (
    <div style={{ position: 'relative', margin: `${my}px ${mx}px` }}>
      <MDivider sx={{ borderTop: `1px solid lightgrey` }} />
      {children && (
        <span
          style={{
            position: 'absolute',
            top: 0,
            backgroundColor: theme.palette.common.white,
            padding: '0px 8px',
            left,
            color: theme.palette.grey[500],
            textTransform: upper ? 'uppercase' : 'none',
            letterSpacing: '0.77px',
            fontFamily: theme.fontFamily,
            fontSize: '11px',
            lineHeight: '11px',
            marginTop: -5
          }}
        >
          {children}
        </span>
      )}
    </div>
  )
}

Divider.propTypes = {
  children: PropTypes.string,
  left: PropTypes.number,
  vertical: PropTypes.bool,
  upper: PropTypes.bool,
  my: PropTypes.number,
  mx: PropTypes.number
}
Divider.defaultProps = {
  children: null,
  left: undefined,
  vertical: false,
  upper: true,
  my: 16,
  mx: 0
}
export default Divider
