import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckCircleOutline, Edit, Notifications } from '@mui/icons-material'
import { Grid } from '@mui/material'
import moment from 'moment'
import { Task } from '../../../utils/fixtures'
import { Text } from '../../atoms'
import TaskForm from '../TaskForm'

export interface TaskListElementProps {
  task?: Task
  onTaskDone?: (task: Task) => void
  onTaskUndone?: (task: Task) => void
  onTaskEdit: (task?: Task) => void
}

const gridItemSx = {
  display: 'flex',
  alignItems: 'center',
  p: 1
}

const TaskListElement = ({
  task,
  onTaskUndone,
  onTaskDone,
  onTaskEdit
}: TaskListElementProps): JSX.Element | null => {
  const { t } = useTranslation('common')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const handleTaskEdit = (changedTask?: Task) => {
    setIsEditing(false)
    onTaskEdit(changedTask)
  }
  if (!task) return null
  if (isEditing) {
    return <TaskForm isEdit task={task} addOrSaveTask={handleTaskEdit} />
  }
  return task?.done ? (
    <Grid container>
      <Grid
        item
        xs="auto"
        sx={{
          ...gridItemSx,
          cursor: 'pointer',
          color: 'black',
          '&:hover': {
            color: 'petrol'
          }
        }}
        onClick={() => onTaskUndone?.(task)}
      >
        <CheckCircleOutline />
      </Grid>
      <Grid item xs="auto" sx={{ ...gridItemSx }}>
        <Text>{task.title}</Text>
      </Grid>
      <Grid item xs="auto" sx={{ ...gridItemSx, pl: 2, pr: 0, color: 'grey.A700' }}>
        <Text>
          <>{t('doneAt', { at: moment(task.updatedAt).format('DD.MM.YYYY - HH.mm') })}</>
        </Text>
      </Grid>
    </Grid>
  ) : (
    <Grid container>
      <Grid
        item
        xs="auto"
        sx={{
          ...gridItemSx,
          cursor: 'pointer',
          color: task.done ? 'black' : 'petrol',
          '&:hover': {
            color: 'black'
          }
        }}
        onClick={() => onTaskDone?.(task)}
      >
        <CheckCircleOutline />
      </Grid>
      <Grid
        item
        xs="auto"
        sx={{
          ...gridItemSx,
          cursor: 'pointer',
          color: 'petrol',
          '&:hover': {
            color: 'black'
          }
        }}
        onClick={() => setIsEditing(true)}
      >
        <Edit fontSize="small" />
      </Grid>
      <Grid item xs="auto" sx={{ ...gridItemSx }}>
        <Text>{task.title}</Text>
      </Grid>
      <Grid
        item
        xs="auto"
        sx={{
          ...gridItemSx,
          pr: '2px',
          color: 'petrol'
        }}
      >
        <Notifications fontSize="small" />
      </Grid>
      <Grid item xs="auto" sx={{ ...gridItemSx, px: 0, color: 'petrol' }}>
        <Text>{moment(task.dueDate).format('DD.MM.YYYY HH:mm')}</Text>
      </Grid>
    </Grid>
  )
}

export default TaskListElement
