import React from 'react'
import { ThemeProvider } from '@emotion/react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CssBaseline
} from '@mui/material'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { createRoot } from 'react-dom/client'
import GlobalStyle from '../../../utils/GlobalStyle'
import theme from '../../../utils/theme'
import { Button } from '../../atoms'

const PromptSaveDialog = (message, callback, confirmOpen, setConfirmOpen) => {
  const container = document.createElement('div')
  container.setAttribute('custom-confirm-view', '')
  const root = createRoot(container)
  const handleConfirm = callbackState => {
    setTimeout(() => {
      root.unmount()
    }, 10)
    callback(callbackState)
    setConfirmOpen(false)
  }

  const handleCancel = () => {
    setTimeout(() => {
      root.unmount()
    }, 10)
    callback()
    setConfirmOpen(false)
  }
  document.body.appendChild(container)
  // prettier-ignore
  const { content, title, abortButtonText = 'cancel', confirmButtonText = 'ok' } = JSON.parse(
    message
  )
  root.render(
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Dialog open={confirmOpen} style={{ font: 'inherit' }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} color="primary" autoFocus>
                {abortButtonText}
              </Button>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                {confirmButtonText}
              </Button>
            </DialogActions>
          </Dialog>
          <GlobalStyle />
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>,
    container
  )
}

export default PromptSaveDialog
