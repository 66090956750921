import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import { debounce } from 'lodash'
import { useFetchAWS } from '../../../utils/hooks'
import { Button } from '../../atoms'

const DownloadButton = ({ options, label }) => {
  const { headers, url } = options || {}
  const [load, setLoad] = useState(false)
  const [{ error, loading }, doFetch] = useFetchAWS({ headers })
  if (error) console.error(error)
  const throttledFetch = debounce(
    () => {
      doFetch(url)
      if (!loading) setLoad(false)
    },
    1000,
    { trailing: true, maxWait: 5000, leading: false }
  )

  const handleClick = () => {
    setLoad(true)
    throttledFetch()
  }
  return (
    <Button startIcon={load && <CircularProgress size={20} />} fullWidth onClick={handleClick}>
      {label}
    </Button>
  )
}

DownloadButton.propTypes = {
  options: PropTypes.object,
  label: PropTypes.string
}

DownloadButton.defaultProps = {
  options: {},
  label: ''
}

export default DownloadButton
