import React from 'react'
import {
  Snackbar,
  Alert as MAlert,
  AlertProps,
  AlertTitle as MAlertTitle,
  AlertTitleProps,
  Portal
} from '@mui/material'

export type TVariant = 'error' | 'warning' | 'info' | 'success'

export type THorizontal = 'left' | 'center' | 'right'

export type TVertical = 'top' | 'bottom'

export interface MessageBoxProps {
  horizontal?: THorizontal
  message?: string
  open?: boolean
  small?: boolean
  autoHide?: boolean
  title?: string
  onClose?: () => void
  onClick?: () => void
  variant?: TVariant
  vertical?: TVertical
}

const MessageBox: React.FC<MessageBoxProps> = ({
  horizontal = 'center',
  message = '',
  open,
  small,
  title,
  variant = 'info',
  vertical = 'bottom',
  autoHide,
  onClose,
  onClick,
  ...rest
}) => {
  const Alert = (props: AlertProps) => (
    <MAlert
      sx={{
        paddingLeft: '30px',
        paddingRight: '50px',
        whiteSpace: 'pre-line',
        alignItems: 'center',
        ...(!small && {
          '& .MuiAlert-icon': {
            fontSize: '30px'
          }
        })
      }}
      {...props}
    />
  )

  const AlertTitle = (props: AlertTitleProps) => (
    <MAlertTitle
      sx={{
        padding: 0,
        margin: 0
      }}
      {...props}
    />
  )
  return (
    <Portal>
      <Snackbar
        open={open}
        onClick={onClick}
        onClose={onClose}
        anchorOrigin={{ horizontal, vertical }}
        autoHideDuration={autoHide ? 2000 : undefined}
      >
        <div>
          <Alert {...rest} severity={variant}>
            <AlertTitle style={{ padding: 0, margin: 0 }}>{title}</AlertTitle>
            {message}
          </Alert>
        </div>
      </Snackbar>
    </Portal>
  )
}

export default MessageBox
