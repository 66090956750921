import React from 'react'
import PropTypes from 'prop-types'
import { Button as MuiButton } from '@mui/material'

const Button = ({ children, startIcon, endIcon, disabled, underline }) => (
  <MuiButton
    startIcon={startIcon}
    endIcon={endIcon}
    disabled={disabled}
    sx={{
      textDecoration: underline ? 'underline' : 'none',
      '&:hover': {
        backgroundColor: 'secondary.main',
        color: 'common.black',
        boxShadow: 'none'
      },
      '&:focus': {
        boxShadow: `0 0 0 1px ${'common.white'}, 0 0 0 3px ${'blueLight'}`
      }
    }}
  >
    {children}
  </MuiButton>
)

Button.propTypes = {
  children: PropTypes.node,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
  underline: PropTypes.bool
}

Button.defaultProps = {
  children: 'Button',
  disabled: false,
  startIcon: null,
  endIcon: null,
  underline: false
}

export default Button
