import { FormControlLabel, FormControlLabelProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface CustomLabelProps extends FormControlLabelProps {}

const CustomLabel: React.FC<CustomLabelProps> = props => (
  <FormControlLabel
    {...props}
    sx={(theme: Theme) => ({
      label: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: theme.typography.lineHeightBig,
        color: theme.palette.common.black
      }
    })}
  />
)
export default CustomLabel
