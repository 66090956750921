import { Tabs as MTabs, TabsProps } from '@mui/material'

const Tabs = (props: TabsProps) => (
  <MTabs
    sx={(theme: any) => ({
      '& .MuiTabs-scrollButtonsDesktop': {
        color: theme.palette?.grey[500]
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette?.grey[500]
      }
    })}
    {...props}
  />
)

export default Tabs
