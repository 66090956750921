import { StrictMode } from 'react'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import * as Sentry from '@sentry/browser'
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration
} from '@sentry/integrations'
import * as SentryReact from '@sentry/react'
import { excludeGraphQLFetch } from 'apollo-link-sentry'
import { createRoot } from 'react-dom/client'
import pkg from '../package.json'
import client from './client'
// import { LocaleProvider } from './LocaleContext'
import reportWebVitals from './reportWebVitals'
import Router from './routes/Router'
import { ProfilContextProvider } from './utils/Context/ProfileContext'
import GlobalStyle from './utils/GlobalStyle'
import theme from './utils/theme'
import './Localization'
import { UserContextProvider } from './utils/UserContext'

const { version } = pkg
const materialLicenseKey = process.env.REACT_APP_MATERIAL_LICENSE_KEY

LicenseInfo.setLicenseKey(materialLicenseKey)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  beforeBreadcrumb: excludeGraphQLFetch,
  beforeSend: event => {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        title: 'Es sieht so aus als wäre ein Fehler aufgetreten.',
        subtitle: 'Das Entwicklerteam wurde benachrichtigt.',
        subtitle2: ' Können Sie uns kurz beschreiben, was zu dem Fehler geführt hat?',
        labelName: 'Name',
        labelEmail: 'Email',
        labelComments: 'Beschreibung Fehlerhergang',
        labelClose: 'Schließen',
        labelSubmit: 'Absenden',
        errorGeneric: 'Während des Hochladens Ihres Feedbacks ist ein Fehler aufgetreten.',
        errorFormEntry: 'Bitte vervollständigen Sie die Felder.',
        eventId: event.event_id,
        lang: 'de'
      })
    }
    return event
  },
  integrations: [
    new SentryReact.BrowserTracing({
      traceFetch: false,
      traceXHR: true,
      tracePropagationTargets: [/m2-loesung.de/, /localhost/]
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      tracePropagationTargets: [/m2-loesung.de/, /localhost/]
    }),
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3
    }),
    new HttpClientIntegration()
  ],
  release: version,
  tracesSampleRate: 0.5,
  // This sets the sample rate to be 40%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.3,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  tracePropagationTargets: [/m2-loesung.de/, /localhost/]
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <UserContextProvider>
              <ProfilContextProvider>
                <Router />
                <CssBaseline />
                <GlobalStyle />
              </ProfilContextProvider>
            </UserContextProvider>
          </ApolloProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
