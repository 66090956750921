import React from 'react'
import PropTypes from 'prop-types'
import {
  LineChart as RLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

const LineChart = ({ height, data, dataOptions, legendPayload, domain }) => (
  <ResponsiveContainer width="100%" height={height}>
    <RLineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis domain={domain} />
      <Tooltip />
      <Legend payload={legendPayload} />
      {dataOptions?.map(dataOption => (
        <Line
          key={dataOption.dataKey}
          type={dataOption.type || 'monotone'}
          dataKey={dataOption.dataKey}
          {...(dataOption.dataKey.includes('intern') && { strokeDasharray: '5 5' })}
          dot={{ strokeWidth: 1, strokeDasharray: '0 0' }}
          stroke={dataOption.stroke}
          connectNulls={dataOption.connectNulls}
          activeDot={dataOption.activeDot}
        />
      ))}
    </RLineChart>
  </ResponsiveContainer>
)
LineChart.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array.isRequired,
  legendPayload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      color: PropTypes.string,
      id: PropTypes.string,
      formatter: PropTypes.any,
      inactive: PropTypes.bool,
      legendIcon: PropTypes.any,
      type: PropTypes.string,
      payload: PropTypes.array
    })
  ),
  dataOptions: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      stroke: PropTypes.string.isRequired,
      activeDot: PropTypes.object,
      type: PropTypes.string,
      connectNulls: PropTypes.bool
    })
  ).isRequired,
  domain: PropTypes.array
}
LineChart.defaultProps = {
  height: 300,
  legendPayload: undefined,
  domain: [0, 100]
}

export default LineChart
